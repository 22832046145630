import React,{useState} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './ApplicationCategoryCard.styles.scss'
import { useNavigate } from 'react-router-dom';
import { APPLICATION_TITLE_MAX_CHAR } from '../../../../constants/appConfig';
import { showToastr } from '../../../ToastrMessage/Toast.helper';
import { APP_LABEL } from '../../../../translations';
import OADialog from '../../OADialog/OADialog.component';



const ApplicationCategoryCard = (props) => {
  const { applicationName,
    applicationStartDate,
    applicationImageURL,
    applicationDescription,
    applicationLastDate,
    applicationsCategoryName
  } = props;
  const navigate = useNavigate();
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [pathToShare, setPathToShare] = useState('');
  const domain = window.location.origin
  const cscCentersPath = `${domain}/services/${applicationsCategoryName}/${applicationName}`
  const handleCardClick = (e) => {
    navigate(`/services/${applicationsCategoryName}/${applicationName}`);
  };
  const trimDownApplicationName = (name) => {
    if (!name) return 'application'
    else if (name.length > APPLICATION_TITLE_MAX_CHAR) return name.substring(0, APPLICATION_TITLE_MAX_CHAR) + '...';
    else return name;
  }
  const applicationCategoryCopyIconHandle = (e) => {
    e?.stopPropagation();
    navigator.clipboard.writeText(cscCentersPath);
    showToastr('success',`${APP_LABEL['en'].copyLinkPrefix} ${applicationName} ${APP_LABEL['en'].copyLinkSuffix}`);
  }
  const applicationCategoryShareIconHandle = (e) => {
    e?.stopPropagation();
    setPathToShare(cscCentersPath)
    setOpenShareDialog(true);
  }

  return (
    <div className='application-card' onClick={handleCardClick}>
      <Card sx={{
        color: 'primary.main', ':hover': {
          borderColor: 'primary.main'
        }, padding: '0.25rem'
      }} className='application-card-container'>
        <CardHeader
          title={trimDownApplicationName(applicationName)}
          subheader={applicationStartDate ?? '01/01/2024'}
          subheaderTypographyProps={{ color: 'primary.main', fontSize:'0.875rem' }}
        />
        <CardMedia
          component="img"
          image={applicationImageURL}
          className='card-media'
          alt="application-image"
        />
        <CardContent>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {applicationDescription}
          </Typography>
        </CardContent>
        <CardActions disableSpacing className='application-card-footer'>
          <span>
            <CardActions>
              <Typography variant="subtitle2" gutterBottom className='last-date'>
                Last Date <br /> {applicationLastDate}
              </Typography>

            </CardActions>
          </span>
          <span>
            <IconButton aria-label="add to favorites" sx={{ color: 'primary.main' }} onClick={applicationCategoryCopyIconHandle} >
              <ContentCopyIcon />
            </IconButton>
            <IconButton aria-label="share" sx={{ color: 'primary.main' }} onClick={applicationCategoryShareIconHandle}>
              <ShareIcon />
            </IconButton>
          </span>
        </CardActions>

      </Card>
      <OADialog
      setOpenShareDialog={setOpenShareDialog}
      openShareDialog={openShareDialog}
      pathToShare={pathToShare}
        // deleteAction={cancelApplication}
        confirmationMessage={APP_LABEL['en'].cancelApplication}
        rightButtonTitle={APP_LABEL['en'].cancelBtnTitle}
      />
    </div>
  );
}
export default ApplicationCategoryCard;