import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import './ApplicationCscCard.styles.scss'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContactsIcon from '@mui/icons-material/Contacts';
import Rating from '@mui/material/Rating';
import { useNavigate, useLocation } from 'react-router-dom';
import { showToastr } from '../../../ToastrMessage/Toast.helper';

const ab = [{
  id: 12,
  qualifications: '10th',
  applicationName: 'Constable ',
  postedBy: 'Common csc center1',
  lastDate: '10/11/2024',
  price: '50',
  location: 'Hisar,Haryana',
  contact: 'Contact'
},
{
  id: 123,
  qualifications: '10th',
  applicationName: 'Constable',
  postedBy: 'Common csc center2',
  lastDate: '10/11/2024',
  price: '60',
  location: 'umra,Hisar,Haryana',
  contact: 'view'
},
{
  id: 1332,
  qualifications: '10th',
  applicationName: 'Constable',
  postedBy: 'Common csc center3',
  lastDate: '10/11/2024',
  price: '70',
  location: 'umra,Hisar,Haryana',
  contact: 'view'
},
{
  id: 12333,
  qualifications: '10th',
  applicationName: 'Constable',
  postedBy: 'Common csc center3',
  lastDate: '10/11/2024',
  price: '70',
  location: 'umra,Hisar,Haryana',
  contact: 'view'
},
{
  id: 1002,
  qualifications: '10th',
  applicationName: 'Constable',
  postedBy: 'Common csc center4',
  lastDate: '10/11/2024',
  price: '80',
  location: 'umra,Hisar,Haryana',
  contact: 'view'
},
{
  id: 12222,
  qualifications: '10th',
  applicationName: 'Constable',
  postedBy: 'Common csc center5',
  lastDate: '10/11/2024',
  price: '90',
  location: 'umra,Hisar,Haryana',
  contact: 'view'
}
]

const ApplicationCscCard = (props) => {
  const location = useLocation();
  const domain = window.location.origin
  const verifyDocURL = `${domain}${location.pathname}/verify-documents`
  const navigate = useNavigate();
  const checkServiceDocuments = () => {
    navigate(`../place-order`);
  }

  const cscUrlCopyIconHandle = (e,cscCenterName) => {
    e?.stopPropagation();
    navigator.clipboard.writeText(verifyDocURL);
    showToastr('success',`Link for ${cscCenterName} center has been copied.`);
  }

  return (
    <>
      <div className='services-csc-list'>
        {
          ab?.map((item) => {
            return (
              <div className='application-csc-card' >
                <Card sx={{
                  color: 'primary.main', ':hover': {
                    backgroundColor: 'background.paper'
                  }, padding: '0.25rem'
                }} className='application-card-list-container'>
                  <CardHeader
                    action={
                      <>
                        <IconButton aria-label="add to favorites" sx={{ color: 'primary.main' }} >
                          <FavoriteBorderIcon />
                        </IconButton>
                        <IconButton aria-label="share" sx={{ color: 'primary.main' }}>
                          <ContentCopyIcon onClick={(e)=>cscUrlCopyIconHandle(e,item?.postedBy)} />
                        </IconButton>
                      </>
                    }

                    title={item?.applicationName ?? 'application name'}
                    sx={{ marginLeft: '0.5rem' }}
                    subheader={item?.postedBy ?? '01/01/2024'}
                    subheaderTypographyProps={{ color: 'primary.main' }}
                  />
                  <CardContent className='card-content'>
                    <Typography variant="body2" sx={{ color: "primary.main" }} className='service-price'>
                      <span className='service-after-dis-price'>₹{item?.price}</span>  &nbsp;<span className='service-before-dis-price'>₹{74} &nbsp;</span> <span className='service-dis-percent'>36% off</span>
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'primary.main' }} className='service-location-style'>
                      <LocationOnIcon sx={{ color: 'primary.main' }} /> &nbsp;  {item?.location}
                    </Typography>
                    <Typography variant="subtitle2" color="secondary.contrastText" className='service-location-style'>
                      <ContactsIcon sx={{ color: 'primary.main' }} /> &nbsp; <Button sx={{ textTransform: 'capitalize' }}>View contact</Button>
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing className='application-card-footer'>
                    <span>
                      <CardActions>
                        <Typography variant="subtitle2" gutterBottom className='last-date'>
                          Last Date: {item?.lastDate}
                        </Typography>


                      </CardActions>
                      <CardActions>
                        <Typography variant="subtitle2" gutterBottom className='csc-rating'>
                          <Rating name="half-rating-read" defaultValue={2.5} precision={0.5} readOnly size="small" />
                        </Typography>

                        <span className='rating-text'>3.5</span>
                      </CardActions>
                    </span>
                    <span>
                      <div className='service-apply-button-container'>
                        <Button variant="outlined" sx={{ color: 'primary.main' }} className={'service-apply-button'} onClick={checkServiceDocuments}>Apply Now</Button>
                      </div>
                    </span>
                  </CardActions>
                </Card>
              </div>
            )
          })

        }
      </div>
    </>
  );
}
export default ApplicationCscCard;