import React from 'react';
import './OrderSummary.styles.scss';

const OrderSummary = () => {
  return (
    <div className="order-summary">
      <h2>Order Summary</h2>
      <div className="order-item">
        <p>Product Name</p>
        <p>Qty: 1</p>
        <p>Price: ₹500</p>
      </div>
    </div>
  );
}

export default OrderSummary;
