import React from "react";
import "./Contact.styles.scss"; // Import your CSS file
import contactLogo from "../../assets/images/images.jpg";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import XIcon from '@mui/icons-material/X';
import TwitterIcon from '@mui/icons-material/Twitter';

const Contact = () => {
  return (
    <>
    <div className="contact-us-container">
      <div className="contact-us-heading">
        <div className="contact-us-main-heading">
          Fewer Calls<br/> Better Assistance
        </div>
        <div className="contact-us-sub-heading">
          We understand the importance of your time and don't want to leave you
          waiting for a phone call. That's why we advocate for the use of live
          chat and other online communication methods, which offer more
          efficient solutions to address your needs
        </div>
      </div>
      <div className="contact-us-heading-image">
        <img
          src={contactLogo}
          width={"100%"}
          height={"100%"}
          alt="contact-image"
        />
      </div>
    </div>
    <div>
    <h3 className="assist-heading">How Can We Assist You?</h3>
    <p  className="assist-sub-heading">We are here for you 24/7. Our Customer Success Team is called that way for a reason. <br/> Email us - onlineapplicationshelpline@gmail.com</p>
    </div>
    <div>
    <h3 className="assist-heading">Our office Locations</h3>
    <p  className="assist-sub-heading">Hisar, Haryana, India</p>
    </div>
    <div>
    <h3 className="assist-heading">Follow us</h3>
    <p  className="assist-sub-heading"><FacebookIcon/> <TwitterIcon/> <LinkedInIcon/></p>
    </div>
    </>
  );
};

export default Contact;
