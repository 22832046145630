import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './OADialog.styles.scss'
import { APP_LABEL } from '../../../translations';
import OADialogBody from './OADialogBody.component';
import React, { useState } from 'react';
import { showToastr } from '../../ToastrMessage/Toast.helper';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const OADialog = (props) => {
  const { openShareDialog, setOpenShareDialog,pathToShare } = props;
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [currentOpenTab, setCurrentOpenTab] = useState(0);

  const handleClose = (e) => {
    e?.stopPropagation();
    setOpenShareDialog(false);
    setIsMobileNumberValid(false);
    setIsEmailValid(false);
  };

  const isButtonDisabled = () => {
    if (currentOpenTab === 0) {
      return !isMobileNumberValid;
    }
    if (currentOpenTab === 1) {
      return !isEmailValid;
    }
    return true;
  };
  const sendTheLocationClick = () =>{
     if (currentOpenTab === 0){
      showToastr('success', APP_LABEL['en'].SERVICE_CENTER_SHARE_MOB_SUCCESS)
     } else if(currentOpenTab === 1){
      showToastr('success', APP_LABEL['en'].SERVICE_CENTER_SHARE_EMAIL_SUCCESS)
     }
   
   

  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openShareDialog}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='dialog-titles-container'>
          <span className='oa-dialog-title'>{APP_LABEL['en'].DIALOG_SHARE_TITLE} </span>
          <span className='oa-dialog-sub-title'>{APP_LABEL['en'].DIALOG_SHARE_SUB_TITLE}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <OADialogBody
            setIsMobileNumberValid={setIsMobileNumberValid}
            setIsEmailValid={setIsEmailValid}
            setCurrentOpenTab={setCurrentOpenTab}
          />
        </DialogContent>
        <DialogActions className='dialog-action-footer'>
          <Button
            autoFocus
            variant="contained"
            sx={{ textTransform: 'none' }}
            disabled={isButtonDisabled()}
            onClick={sendTheLocationClick}
          >
            Send the Location
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
export default OADialog;