import { Box, Modal } from '@mui/material';
import React,{ useState} from 'react';
import Login from '../../../pages/Authentication/Login/Login.component';
const LoginModal = ({openLoginModal, setOpenLoginModal}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "max-content",
        bgcolor: "background.paper",
        borderRadius: "0.4rem !important",
        overflow: "hidden",
        boxShadow: 24,
      };

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const closeModal = ()=>{
        setOpenLoginModal(false);
    }
    return (
        <Modal
        className="login-modal"
        open={openLoginModal}
        elevation={0}
        onClose={closeModal}
      >
        <Box sx={style}>
          <Login
            setOpen={setOpenLoginModal}
            setIsUserLoggedIn={setIsUserLoggedIn}
            setUserInfo={setUserInfo}
            setDisplayName={setDisplayName}
          />
        </Box>
      </Modal>
    )
}

export default LoginModal;