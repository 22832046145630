import React from 'react';
import appLogo from "../../assets/images/Capture1.PNG";
import { Link } from 'react-router-dom';
const Logo = ({width}) => {
  return (
    <Link to ="/">
       <img
                src={appLogo}
                width={width || "50px"}
                alt="appLogo"
              />
    </Link>
  )
}
export default Logo;