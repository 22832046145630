import React from 'react';
import './About.styles.scss';

const About = () => {
  return (
    <div className="about-us-container">
      <h1>About Us</h1>
      <p>
        Welcome to our platform where you can apply for jobs online and avail other services such as making Aadhar card, PAN card, and paying electricity bills.
      </p>
      <h2>Our Mission</h2>
      <p>
        Our mission is to provide a convenient and efficient platform for individuals to apply for jobs from the comfort of their homes and access essential services without hassle.
      </p>
      <h2>Job Applications</h2>
      <p>
        You can apply for various job opportunities available on our platform by filling out the online application forms provided. We strive to connect job seekers with suitable employment opportunities.
      </p>
      <h2>Other Services</h2>
      <p>
        In addition to job applications, we offer services such as:
        <ul>
          <li>Creating Aadhar card</li>
          <li>Applying for PAN card</li>
          <li>Electricity bill payment</li>
        </ul>
        Our user-friendly interface makes it easy to avail these services online, saving you time and effort.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any inquiries or require assistance, feel free to contact us at <b>onlineapplicationshelpline@gmail.com</b> or call us at <b>+91-7982019022</b>.
      </p>
    </div>
  );
}

export default About;
