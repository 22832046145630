export const APP_LABEL_EN = {
    removeDocument: 'Are you sure you want to remove document?',
    cancelApplication: 'Are you sure you want to cancel application?',
    rightBtnTitle: 'Yes',
    leftBtnTitle: 'No',
    copyLinkPrefix: 'Link for',
    copyLinkSuffix: 'application has been copied to clipboard.',
    ERROR_ACCOUNT_DISABLED : "Account disabled due to many failed login attempts.Either reset your password or you can try again later.",
    ERROR_NO_INTERNET_CONNECTION: "Please check your internet connection.",
    DIALOG_SHARE_TITLE:' Enter Email ID or Mobile Number to receive the service centers',
    DIALOG_SHARE_SUB_TITLE:' The service centers will be shared on your device',
    SERVICE_CENTER_SHARE_MOB_SUCCESS: 'Service centers link has been shared to your mobile device.',
    SERVICE_CENTER_SHARE_EMAIL_SUCCESS: 'Service centers link has been shared to email address.',
    FILTER_APP_HEADING: 'Service category',
    FILTER_APP_STATE_HEADING:'State',
    FILTER_APP_STATE_HEADING_DESC: 'Find Your Next Career in State Government to Serve in Local Bodies',
    FILTER_APP_QUALIFICATION_HEADING:'Qualification',
    FILTER_APP_QUALIFICATION_HEADING_DESC: 'Explore Jobs Tailored to Your Professional Qualification',
    FILTER_APP_POPULAR_CATEGORIES_HEADING:'Popular categories',
    FILTER_APP_POPULAR_CATEGORIES_HEADING_DESC: 'Explore Popular Jobs Categories in the India Government',
    FILTER_APP_COMMON_SERVICE_HEADING:'Common Services',
    FILTER_APP_COMMON_SERVICE_HEADING_DESC: 'Apply for Services like Aadhar, PAN Card etc. in few Clicks',
    CHOOSE_US_HEADING: 'Why choose us?',
    CHOOSE_US_NO_QUEUE: 'No Queue',
    CHOOSE_US_NO_QUEUE_DESCRIPTION: 'No waiting line or lineup for the service or online application, you are a request away for online service.',
    CHOOSE_US_LOW_CHARGES: 'Low Charges',
    CHOOSE_US_LOW_CHARGES_DESCRIPTION: 'Cost-effective advantages to using the online platform compared to traditional methods.',
    CHOOSE_US_EASY_APPLY: 'Easy Apply',
    CHOOSE_US_EASY_APPLY_DESCRIPTION: 'Apply for online jobs or services with just a few clicks and fill out the required forms.',
    BEST_PLACE_INFO_HEADING:'Over 1k+',
    BEST_PLACE_INFO_DESC: 'customers trust us & have apply their service on online application portal',
    BEST_PLACE_SERVICE_CENTER_HEADING: '50+ CSC',
    BEST_PLACE_SERVICE_CENTER_DESC: 'partnered with us so that you can compare easily & transparently',
    BEST_PLACE_LOWEST_PRICE_HEADING: 'Great price',
    BEST_PLACE_LOWEST_PRICE_DESC: 'for all kinds of online services like job forms, aadhar, pan etc',
    BEST_PLACE_FAST_SERVICE_HEADING: 'Easy',
    BEST_PLACE_FAST_SERVICE_DESC: 'to explore, select, and apply to get all services at one place in few clicks',
    BEST_PLACE_LIVE_STATUS_HEADING: 'Live status',
    BEST_PLACE_LIVE_STATUS_DESC: 'of applied services and you can track applicaiton status',
    DOCUMENT_UPLOADED_SUCCESS_MESSAGE: 'Document has been uploaded successfully'




}