import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider'; 
import './GeneralDialog.styles.scss';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const  GeneralDialog = ({openDialog,setOpenDialog,dialogHeader,dialogBody, showDialogFooter,dialogFooterActionTextLeft,dialogFooterActionTextRight,leftButtonClick}) => {

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      
        sx={{overflow:'none'}}
        // className='dialog-title'
        // maxWidth={'sm'}
        className={'oa-general-dialog'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='general-dialog-title'>
         {dialogHeader ?? 'Modal title'}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {dialogBody}

      {showDialogFooter &&  
    <>
      <Divider /> 
 <DialogActions>
 {dialogFooterActionTextLeft && <Button autoFocus onClick={leftButtonClick} sx={{textTransform: 'none',margin: 1}} variant='contained'>
          {dialogFooterActionTextLeft ??  'Save changes'}
          </Button>
}
    {dialogFooterActionTextRight &&      <Button autoFocus onClick={handleClose} sx={{textTransform: 'none',margin: 1}} variant='outlined'>
          {dialogFooterActionTextRight ??  'Cancel'}
          </Button>
}
        </DialogActions>
        </> 
}
      </BootstrapDialog>
    </React.Fragment>
  );
}
export default GeneralDialog;