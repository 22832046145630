import React from 'react';
import './StateList.styles.scss'
import chandigarhImage from '../../../assets/stateimages/chandigarh.jpg';
import delhiImage from '../../../assets/stateimages/delhi.jpg';
import haryanaImage from '../../../assets/stateimages/haryana.jpg';
import punjabImage from '../../../assets/stateimages/punjab.jpg';
import rajasthanImage from '../../../assets/stateimages/rajasthan.jpg';
import hpImage from '../../../assets/stateimages/hp.jpg';
import upImage from '../../../assets/stateimages/up.jpg';
import Typography from '@mui/material/Typography';

const stateData = [
    { name: 'Haryana', imageURL: haryanaImage },
    { name: 'Rajasthan', imageURL: rajasthanImage },
    { name: 'Chandigarh', imageURL: chandigarhImage},
    { name: 'Delhi', imageURL: delhiImage },
    { name: 'Uttar Pardesh', imageURL: upImage },
    { name: 'Himachal Pradesh', imageURL: hpImage },
    { name: 'Punjab', imageURL: punjabImage }
];


const StateList = ({setCurrentSelectedState}) => {
    return (
        <div className="state-list-container">
            {stateData.map((state, index) => (
                <div key={`${index + '_statelist'}`} className="state-item">
                    <img src={state.imageURL} alt={state.name} className="state-image" width={125} height={105} onClick={()=>setCurrentSelectedState(state.name)}/>
                    <Typography  variant="subtitle1">{state.name}</Typography>
                </div>
            ))}
        </div>
    );
};

export default StateList;
