import {React} from 'react';
import './AppFooter.styles.scss'
import { useTheme } from '@mui/material/styles';
import Logo from '../../../components/Logo/Logo.component';
const AppFooter = () => {
  const theme = useTheme();

  return (
    <div className='footer-container' style={{backgroundColor: theme.palette.background.footer }}>
      <div className='logo-container'>
        <div className='logo-wrapper'>
          <div className='container'><a href='/'><Logo /></a></div>
          <div className='container'><a href='/' className='footer-title'>Online Applications</a></div>
        </div>
        <div className='text-wrapper'>
          <p className='footer-text'>484, siswala farm house</p>
          <p className='footer-text'>
            Hisar, 125004, Haryana</p>
          <p className='footer-text'>
            HISAR – 125001</p>
        </div>
      </div>
      <div className='catagories-container'>
        <div className='footer-item'>
          <p className='footer-title'>products</p>
          <a  className='footer-text'>Govt. Exam Forms</a>
          <a  className='footer-text'>Online services</a>
          <a  className='footer-text'>PAN card/Aadhar card</a>
        </div>
        <div className='footer-item'>
          <p className='footer-title'>quick links</p>
          <a >Application Status</a>
          <a >Services</a>
        </div>
        <div className='footer-item'>
          <p className='footer-title'>Click and Apply</p>
          <a ></a>
        </div>
      </div>
    </div>
  )
}
export default AppFooter;