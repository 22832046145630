import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const Filedeletedialog = ({ setViewDeleteDialog, viewDeleteDialog ,selectedFiles,setSelectedFiles, currentSelectedFile,setRequiredDocument}) => {
  const radioGroupRef = React.useRef(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };


  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        TransitionProps={{ onEntering: handleEntering }}
        open={viewDeleteDialog}
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize: '0.9rem'}}>
        {`Are you sure you want to delete ${currentSelectedFile?.file?.name} ?`}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setViewDeleteDialog(false);
            }}
            sx={{fontSize: '0.7rem'}}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
             
              const { [currentSelectedFile?.file?.name]: deletedFile, ...restFiles } = selectedFiles;
              setSelectedFiles(restFiles);
              setViewDeleteDialog(false);
              if(setRequiredDocument){
                setRequiredDocument(prev=>{
                   prev.forEach(doc=>{
                    if(doc.id === currentSelectedFile?.type) {
                      doc.isUploaded = false;
                    }
                })
                return prev;
                  // console.log(prev,currentSelectedFile?.type)
                })
              }
             
            }}
            sx={{color: 'error.main',fontWeight: 700, fontSize: '0.7rem'}}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Filedeletedialog;