import React from 'react';
import './BestPlace.styles.scss'
import { APP_LABEL } from '../../translations';
const BestPlace = () => {
    return (
        <>
            <div className='best-place-container'>
                <div className='best-place-heading'>
                    What makes<br /> <span>Online applications</span> one of<br /> <span>India's favourite place</span> <br /> to apply service in <span> few minutes</span>?
                </div>

                <div className='best-place-right-block'>
                    <div className='best-place-info-box info'>
                        <span className='top-icon'>&#127881;</span>

                        <p className='head'>{APP_LABEL['en'].BEST_PLACE_INFO_HEADING}</p>
                        <p className='copy'>{APP_LABEL['en'].BEST_PLACE_INFO_DESC}</p>
                    </div>
                    <div className='best-place-info-box service-center'>
                        <span className='top-icon'>&#128269;</span>

                        <p className='head'>{APP_LABEL['en'].BEST_PLACE_SERVICE_CENTER_HEADING}</p>
                        <p className='copy'>{APP_LABEL['en'].BEST_PLACE_SERVICE_CENTER_DESC}</p>
                    </div>
                    <div className='best-place-info-box lowest-price'>
                        <span className='top-icon'>&#129321;</span>

                        <p className='head'>{APP_LABEL['en'].BEST_PLACE_LOWEST_PRICE_HEADING}</p>
                        <p className='copy'>{APP_LABEL['en'].BEST_PLACE_SERVICE_CENTER_DESC}c</p>
                    </div>
                    <div className='best-place-info-box fast-service'>
                        <span className='top-icon'>&#129333;</span>

                        <p className='head'>{APP_LABEL['en'].BEST_PLACE_FAST_SERVICE_HEADING}</p>
                        <p className='copy'>{APP_LABEL['en'].BEST_PLACE_FAST_SERVICE_DESC}</p>
                    </div>
                    <div className='best-place-info-box live-status'>
                        <span className='top-icon'>&#128202;</span>

                        <p className='head'>{APP_LABEL['en'].BEST_PLACE_LIVE_STATUS_HEADING}</p>
                        <p className='copy'>{APP_LABEL['en'].BEST_PLACE_LIVE_STATUS_DESC}</p>
                    </div>

                </div>


            </div>
        </>
    )
}
export default BestPlace;