import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Modal from "@mui/material/Modal";
import "./Navbar.styles.scss";
import Login from "../../pages/Authentication/Login/Login.component";
import { FirebaseAuthContext } from "../../auth/FirebaseAuth";
import {
  getLoggedInUserInfo,
  getUserDisplayName,
  isUserAuthenticated,
} from "../../utils/authUtil";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { setThemeMode } from "../../store/theme/themeSlice";
import TimelineIcon from "@mui/icons-material/Timeline";
import CallIcon from "@mui/icons-material/Call";
import StoreIcon from "@mui/icons-material/Store";
import CloseIcon from "@mui/icons-material/Close";
import GroupsIcon from "@mui/icons-material/Groups";
import UserProfileMenu from "../UserProfileMenu/UserProfileMenu.component";
import Logo from "../Logo/Logo.component";
import MenuIcon from "@mui/icons-material/Menu";
import UserProfileMobileDrawer from "../UserProfileMobileDrawer/UserProfileMobileDrawer.component";
import ToastrMessage from "../ToastrMessage/ToastrMessage.component";
import AppMobileDrawer from "../AppMobileDrawer/AppMobileDrawer.component";
import { userDisplayName } from "../../utils/user";
import { userInfoSelector } from "../../store/user/user.selector";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  borderRadius: "0.4rem !important",
  overflow: "hidden",
  boxShadow: 24,
};

const Navbar = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(isUserAuthenticated());
  const storage =localStorage.getItem('userTheme') || 'light';
  const [mode, setMode] = useState(storage);
  const [value, setValue] = useState(-1);
  const loggedInUserDetails = useSelector(userInfoSelector)
  const [toggleAppMobileDrawer, setToggleAppMobileDrawer] = useState(false);
  const [toggleUserProfileMobileDrawer, setToggleUserProfileMobileDrawer] = useState(false);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState(getLoggedInUserInfo());
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState(getUserDisplayName());
  const [open, setOpen] = useState(false);
  const { logOutUser } = useContext(FirebaseAuthContext);
  const currentActiveTab = useLocation();
  const profileImageLetters = userDisplayName(userInfo?.displayName)
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const secretNavigationItems = isUserLoggedIn
    ? [
        {
          name: "Track Applications",
          icon: <TimelineIcon />,
          to: "/track-applications",
        },
      ]
    : [];
  const navigationItems = [
    { name: "Services", icon: <StoreIcon />, to: "/services" },
    ...secretNavigationItems,
    { name: "About us", icon: <GroupsIcon />, to: "/about-us" },
    { name: "Contact us", icon: <CallIcon />, to: "/contact-us" },
  ];

  const openLoginModal = () => {
    setOpen(!open);
  };
  const signOut = () => {
    logOutUser()
      .then(() => {
        localStorage.clear();
        setIsUserLoggedIn(false);
        setUserInfo(null);
        setToggleUserProfileMobileDrawer(false);
        setToggleAppMobileDrawer(false);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    dispatch(setThemeMode(mode));
  }, [mode, dispatch]);
  useEffect(() => {
    navigationItems.forEach((item, index) => {
      if (item.to === currentActiveTab.pathname) {
        setValue(index);
      }
    });
  }, []);
  useEffect(() => {
    setUserInfo(loggedInUserDetails?.loggedInUserInfo ?? getLoggedInUserInfo());
    setDisplayName(loggedInUserDetails?.userDisplayName ?? getUserDisplayName());
    setIsUserLoggedIn(isUserAuthenticated()); // Update login status
  }, [loggedInUserDetails]); // Update user info and display name when loggedInUserDetails changes

  return (
    <>
      <AppBar
        position="sticky"
        style={{ background: "primary.main", boxShadow: "none", position: 'fixed' }}
      >
        <Toolbar>
          {isMatch ? (
            <>
              {!toggleAppMobileDrawer ? (
                <MenuIcon
                  sx={{ color: "primary", fontSize: 30 }}
                  onClick={() => {
                    setToggleAppMobileDrawer(!toggleAppMobileDrawer);
                    setToggleUserProfileMobileDrawer(false);
                  }}
                />
              ) : (
                <CloseIcon
                  onClick={() => {
                    setToggleAppMobileDrawer(!toggleAppMobileDrawer);
                    setToggleUserProfileMobileDrawer(false);
                  }}
                  sx={{ color: "primary", fontSize: 30 }}
                />
              )}
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: "700",
                  fontFamily: "revert",
                  marginLeft: "auto",
                }}
              >
                Online Applications
              </Typography>

              {!isUserLoggedIn ? (
                <Button
                  sx={{
                    "&:hover": {
                      backgroundColor: "background.default",
                      color: "primary.main",
                      borderColor: "primary.main",
                    },
                  }}
                  color="secondary"
                  className="login-button"
                  endIcon={<ChevronRightIcon />}
                  variant="outlined"
                  onClick={openLoginModal}
                >
                  Login
                </Button>
              ) : (
                <>
                  {!toggleUserProfileMobileDrawer ? (
                    <Avatar
                      alt="user"
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        setToggleAppMobileDrawer(false);
                        setToggleUserProfileMobileDrawer(!toggleUserProfileMobileDrawer);
                      }}
                      className="avatar-bg"
                    >
                      {profileImageLetters || "qser"}
                    </Avatar>
                  ) : (
                    <CloseIcon
                      onClick={() => {
                        setToggleAppMobileDrawer(false);
                        setToggleUserProfileMobileDrawer(!toggleUserProfileMobileDrawer);
                      }}
                      sx={{
                        color: "primary",
                        fontSize: 30,
                        marginLeft: "auto",
                      }}
                    />
                  )}
                  <UserProfileMobileDrawer
                    userInfo={userInfo}
                    signOut={signOut}
                    setToggleUserProfileMobileDrawer={setToggleUserProfileMobileDrawer}
                    toggleUserProfileMobileDrawer={toggleUserProfileMobileDrawer}
                  />
                </>
              )}

              <AppMobileDrawer
                toggleAppMobileDrawer={toggleAppMobileDrawer}
                setToggleAppMobileDrawer={setToggleAppMobileDrawer}
                openLogModal={openLoginModal}
                navigationItems={navigationItems}
                mode={mode}
                setMode={setMode}
              />
            </>
          ) : (
            <>
              <Logo />
              <Tabs
                sx={{ marginLeft: "auto" }}
                textColor={"secondary"}
                indicatorColor={"secondary"}
                value={(currentActiveTab.pathname.includes('profile') || currentActiveTab.pathname.includes('documents')) ? -1 : value}
                onChange={(e, value) => {
                  setValue(value);
                }}
              >
                {navigationItems.map((navItem, index) => {
                  return (
                    <Tab
                      key={index}
                      label={navItem.name}
                      component={Link}
                      to={navItem.to}
                    />
                  );
                })}
              </Tabs>
              {isUserLoggedIn ? (
                <>
                  <UserProfileMenu
                    displayName={displayName}
                    userInfo={userInfo}
                    signOut={signOut}
                  />
                </>
              ) : (
                <>
                  <Button
                    sx={{
                      "&:hover": {
                        backgroundColor: "background.default",
                        color: "primary.main",
                        borderColor: "primary.main",
                      },
                    }}
                    color="secondary"
                    className="login-button"
                    endIcon={<ChevronRightIcon />}
                    variant="outlined"
                    onClick={openLoginModal}
                  >
                    Login
                  </Button>
                </>
              )}
              <span
                className="light-dark-mode-icon"
                onClick={() =>{
                  if( mode === 'light') {
                    localStorage.setItem("userTheme", "dark")
                  } else {
                    localStorage.setItem("userTheme", "light")
                  }

                    setMode((prev) =>
                      prev === "light"
                        ? "dark"
                        : "light"
                    )
                }
              }
              >
                {mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
              </span>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Modal
        className="login-modal"
        open={open}
        elevation={0}
        onClose={openLoginModal}
      >
        <Box sx={style}>
          <Login
            setOpen={setOpen}
            setIsUserLoggedIn={setIsUserLoggedIn}
            setUserInfo={setUserInfo}
            setDisplayName={setDisplayName}
          />
        </Box>
      </Modal>
    </>
  );
};
export default Navbar;
