import { format } from 'date-fns';
export const getSystemFormattedDate = (value, dateTimeFormat = 'dd-MM-yyyy HH:mm') => {
    if (!value || isNaN(Number(value))) return;
    const setMeridien = value?.toString().includes("AM") ? "A" : "a";
    const dateTimeWithMeridian = `${dateTimeFormat} ${setMeridien}`;
    const convertedFormat = format(value, dateTimeWithMeridian);
    return convertedFormat;
    // const formattedDate = format(date, dateFormat);
    // console.log(formattedDate);
    // return formattedDate;
 }

