import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import { Paper, Typography, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Visibility from "@mui/icons-material/Visibility";
import Fileviewdialog from '../../../pages/UserDetails/UserDocuments/FileViewDialog/Fileviewdialog.componnet';
import Filedeletedialog from '../../../pages/UserDetails/UserDocuments/FileDeleteDialog/Filedeletedialog.component';
import AddIcon from '@mui/icons-material/Add';
import GeneralDialog from '../../common/GeneralDialog/GeneralDialog.component';
import DocumentUploadDialog from './DocumentUploadDialog.component';
import CloseIcon from '@mui/icons-material/Close';
import { getSystemFormattedDate } from '../../../utils/dateFormats';
import './DocumentUploadAndVerification.styles.scss';
import { getChipColor } from '../../../utils/user';

const DocumentUploadAndVerification = () => {
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [requiredDocument, setRequiredDocument] = useState([{ id: '10', name: '10th', isUploaded: false }, { id: '12', name: '12th', isUploaded: false }, { id: 'grad', name: 'Graduation', isUploaded: false }, { id: 'postGrad', name: 'Post Graduation', isUploaded: false },{ id: 'aadhar', name: 'AADHAR', isUploaded: false },{ id: 'pandCard', name: 'Pan Card', isUploaded: false }])

  const [documentsList, setDocumentsList] = useState({});
  const [open, setOpen] = React.useState(false);
  const [viewDeleteDialog, setViewDeleteDialog] = React.useState(false);
  const [currentSelectedFile, setCurrentSelectedFile] = useState({});

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const getCategoryName = (type) => {
    let categoryName = '';
        requiredDocument.forEach((item)=> {
          if(item?.id === type){
            categoryName = item?.name;
          }
        })
        return categoryName;
  }
  const downloadFile = (fileDetails) => {
    const link = document.createElement("a");
    link.href = fileDetails?.content;
    link.download = fileDetails?.file?.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
    <div className='chips-list'>
      <div>
      <Stack direction="row" spacing={1}  className= 'category-doc-chip-container'>
        {
          requiredDocument?.map((doc, index) => (
            <Chip
              key={index}
              label={doc.name}
              size='small'
              onClick={handleClick}
              onDelete={handleDelete}
              className='category-doc-chip'
              color={doc.isUploaded ? 'success' : 'error'}
              deleteIcon={doc.isUploaded ? <DoneIcon /> : <CloseIcon />}
            />
          ))
        }


      </Stack>
      </div>
      <div className='add-doc-btn'>
      <Button
        variant="outlined"
        color={"primary"}
        sx={{ 'textTransform': 'none' }}
        onClick={() => {
          setOpenFileUploadDialog(true)
        }}
        endIcon={<AddIcon />}
      >
        Add Document
      </Button>
      </div>
      </div>
     
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                width={"120"}
                align="start"
                sx={{ fontWeight: "700", color: "primary.main" }}
              >
                Document Name
              </TableCell>
              <TableCell
                width={"120"}
                align="center"
                sx={{ fontWeight: "700", color: "primary.main" }}
              >
              Document Category
              </TableCell>
              <TableCell
                width={"120"}
                align="center"
                sx={{ fontWeight: "700", color: "primary.main" }}
              >
              Uploaded on
              </TableCell>
              <TableCell
                width={"150"}
                sx={{ fontWeight: "700", color: "primary.main" }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                width={"150"}
                sx={{ fontWeight: "700", color: "primary.main" }}
                align="center"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!Object.keys(documentsList).length && (
              <span> You have no file selected</span>
            )}
            {Object.keys(documentsList).map((name) => (
              <TableRow key={name}>
                <TableCell component="th" scope="row" align="start">
                  {name}
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                   
                    <Typography variant="body2" color="primary">
                      {getCategoryName(documentsList[name].type)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                   
                    <Typography variant="body2" color="primary">
                    { getSystemFormattedDate(documentsList[name].currentDateTime, 'dd-MM-yyyy HH:mm')}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                   
                    <Typography variant="body2" sx={{color: `${documentsList[name].progress}` === '100' ? 'green' : 'red'}}>
                      <Chip label={`${documentsList[name].progress}` === '100' ? 'Complete' : 'Failed'} size="small" variant="outlined"  color={getChipColor(`${documentsList[name].progress}` === '100' ? 'uploaded' : 'Cancelled')} sx={{width: '7rem', fontSize: '0.8rem'}}/>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center" sx={{display:'flex'}}>
                  <Visibility
                    sx={{ color: "primary.main", fontSize: 20 }}
                    className="file-icons-hover"
                    onClick={(e) => {
                      setOpen(true);
                      setCurrentSelectedFile(documentsList[name]);
                    }}
                  />
                  &nbsp;
                  <FileDownloadIcon
                    sx={{ color: "primary.main", fontSize: 20 }}
                    className="file-icons-hover"
                    onClick={() => {
                      downloadFile(documentsList[name]);
                    }}
                  />
                  &nbsp;
                  <DeleteIcon
                    sx={{ color: "primary.main", fontSize: 20 }}
                    onClick={() => {
                      setViewDeleteDialog(true);
                      setCurrentSelectedFile(documentsList[name]);
                    }}
                    className="file-icons-hover"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fileviewdialog
        open={open}
        setOpen={setOpen}
        currentSelectedFile={currentSelectedFile}
        downloadFile={downloadFile}
      />
      <Filedeletedialog
        setViewDeleteDialog={setViewDeleteDialog}
        viewDeleteDialog={viewDeleteDialog}
        setSelectedFiles={setDocumentsList}
        selectedFiles={documentsList}
        currentSelectedFile={currentSelectedFile}
        setRequiredDocument={setRequiredDocument}
      />



      <GeneralDialog
        openDialog={openFileUploadDialog}
        setOpenDialog={setOpenFileUploadDialog}
        dialogHeader={'Select category and upload document'}
        showDialogFooter={true}
        dialogFooterActionTextRight={'Cancel'}
        dialogBody={<DocumentUploadDialog requiredDocument={requiredDocument} setRequiredDocument= {setRequiredDocument} setDocumentsList={setDocumentsList} setOpenDialog={setOpenFileUploadDialog}/>}
      />
    </>
  );
};

export default DocumentUploadAndVerification;
