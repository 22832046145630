import React, { useRef } from 'react';
import ServiceCard from '../../components/common/Cards/ApplicationCategoryCard/ApplicationCategoryCard.component.js';
import './HomePage.styles.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { applicationsWithQualification } from './mockdata';
import Chip from '@mui/material/Chip';
import { Button } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import FilterApplications from '../../components/FilterApplications/FilterApplications.component.js';
import ChooseUs from '../../components/ChooseUs/ChooseUs.component.js';
import BestPlace from '../../components/BestPlace/BestPlace.component.js';
import CustomerReview from '../../components/CustomerReview/CustomerReview.component.js';

const HomePage = () => {
    const scrollContainerRefs = useRef([]);
    const applicationCardListContainer = useRef([]);

    const scrollLeft = (index) => {
        if (scrollContainerRefs.current[index]) {
            scrollContainerRefs.current[index].scrollBy({
                left: -600,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = (index) => {
        if (scrollContainerRefs.current[index]) {
            scrollContainerRefs.current[index].scrollBy({
                left: 600,
                behavior: 'smooth'
            });
        }
    };

    return (
        <>
            <div className='home-page-main-heading-container'>
                <div className='home-headings'>
                    <span className='home-page-heading-intro'>
                        Confused with multiple applications?
                    </span>
                    <div>

                        <span className='home-page-heading'>
                            All online applications,<br /> right here.
                        </span>
                    </div>
                    <div className='home-page-subtitle'>
                        Find and apply to all the latest online applications in one place.
                    </div>
                    <div className='home-page-heading-btn-container'>
                        <Button
                            className='home-page-heading-btn'
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                applicationCardListContainer &&  applicationCardListContainer.current.scrollIntoView({ behavior: 'smooth' });
                            }}
                            endIcon={
                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <KeyboardDoubleArrowDownIcon color="inherit" size={24} /></span>
                            }
                        >
                            Explore latest applications
                        </Button>

                    </div>
                    <div className='horizontal-sep-div'>
                        <div className='horizontal-separator'>

                        </div>
                    </div>
                </div>
            </div>
            <FilterApplications applicationCardListContainerRef={applicationCardListContainer} />
            <BestPlace />
            <div className='oa-service-category-section-header' ref={applicationCardListContainer}>
                <h2>  What would you like to apply today?</h2>
            </div>

            {applicationsWithQualification[0]?.applicationsList.map((item, index) => (
                <div key={index + 'card'} className='general-application-card-list-container' >
                    <span className='application-qualification-header'>   <Chip label={item?.applicationsCategoryName} /></span>
                    <button className='scroll-button scroll-button-left' onClick={() => scrollLeft(index)}>
                        <ArrowBackIosIcon sx={{ fontSize: 20, color: 'secondary.contrastText' }} />
                    </button>
                    <div
                        className='general-application-card-list'
                        ref={(el) => (scrollContainerRefs.current[index] = el)}
                    >
                        {item?.applicationsListByCategory?.map((app, appIndex) => (
                            <ServiceCard
                                key={`${index}_${appIndex}_applicationcard`}
                                applicationName={app?.applicationName}
                                applicationStartDate={app?.applicationStartDate}
                                applicationImageURL={app?.applicationImageURL}
                                applicationDescription={app?.applicationDescription}
                                applicationLastDate={app?.applicationLastDate}
                                applicationsCategoryName={item?.applicationsCategoryName}
                            />
                        ))}
                    </div>
                    <button className='scroll-button scroll-button-right' onClick={() => scrollRight(index)}>
                        <ArrowForwardIosIcon sx={{ fontSize: 20, color: 'secondary.contrastText' }} />
                    </button>
                </div>
            ))}
          
            <CustomerReview/>
            <ChooseUs />
        </>
    );
};

export default HomePage;
