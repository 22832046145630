import * as React from 'react';
import ApplicationCscCardHeader from '../../components/ApplicationCscCardHeader/ApplicationCscCardHeader.component';
import ApplicationCscCard from '../../components/common/Cards/ApplicationCscCard/ApplicationCscCard.component';
import {Outlet} from 'react-router-dom'

const ApplicationCscCardContainer = () => {
  return (
    <>
    <ApplicationCscCardHeader/>
   <Outlet/>
    </>
  );
}
export default ApplicationCscCardContainer;