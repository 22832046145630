import React from "react";
import { Outlet } from "react-router-dom";
import "./UserDetails.styles.scss";

const UserDetails = () => {
  return (
    <div className="profile-body">
      <Outlet />
    </div>
  );
};
export default UserDetails;
