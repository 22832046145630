import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { ListItemText } from "@mui/material";
import "./UserProfileMenu.styles.scss";
import { Link } from "react-router-dom";
import { userDisplayName } from "../../utils/user";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
const UserProfileMenu = ({ displayName, userInfo, signOut }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
    const profileImageLetters = userDisplayName(displayName)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const name = userInfo?.displayName.split(" ").join("");
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "auto"
        }}
      >
        <Tooltip title={userInfo?.displayName}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32,padding: '0.10rem' }}  className="avatar-bg">
              {profileImageLetters || "user"}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "background.default",
            width: "20rem",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="user-profile-account">Account</div>
        <MenuItem >
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginLeft: "0.80rem" 
            }}
          >
            <Avatar
              sx={{ width: 32, height: 32, backgroundColor: "primary.main", padding: '0.10rem'}}
            >
              {profileImageLetters || "user"}
            </Avatar>
            <div>
              <div>{userInfo?.displayName}</div>
              <small>{userInfo?.email}</small>
            </div>
          </div>{" "}
        </MenuItem>
        <Divider />
        <MenuItem className="myprofile-item">
        <Link to={`/${name}/profile`} onClick={handleClose}>
          <ListItemIcon style={{ marginLeft: "0.75rem" }}>
            <PersonOutlineIcon sx={{ color: "primary.main", fontSize: 24 }} />
          </ListItemIcon>
          Profile
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem className="myprofile-item">
        <Link to={`/${name}/documents`} onClick={handleClose}>
          <ListItemIcon style={{ marginLeft: "0.75rem" }}>
          <FileCopyOutlinedIcon sx={{ fontSize: 24, color: "primary.main" }}/>
          </ListItemIcon>
          Documents
          </Link>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={signOut}>
          <ListItemIcon style={{ marginLeft: "0.75rem" }}>
            <PowerSettingsNewIcon sx={{ color: "#FF0000" }} />
          </ListItemIcon>

          <ListItemText
            className="list-items-text"
            sx={{ color: true ? "#FF0000" : "primary.main" }}
          >
            {true ? "Logout" : "Login/Signup"}
          </ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
export default UserProfileMenu;
