import React from 'react';
import './PriceDetails.styles.scss';

const PriceDetails = () => {
  return (
    <div className="price-details">
      <h2>Price Details</h2>
      <div className="price-item">
        <p>Price (1 item)</p>
        <p>₹500</p>
      </div>
      <div className="price-item">
        <p>Delivery Charges</p>
        <p>₹50</p>
      </div>
      <div className="price-item">
        <p>Total Amount</p>
        <p>₹550</p>
      </div>
    </div>
  );
}

export default PriceDetails;
