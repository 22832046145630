import React from "react";
import './ApplicationCscCardHeader.styles.scss'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate,useLocation } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
const ApplicationCscCardHeader = () =>{
   const navigate = useNavigate();
   const location = useLocation();
   const backToCscCategoryList = () => {
       navigate('/')
     }

     const breadcrumbs = [
       <Link key="1"  sx={{color: 'primary.main', cursor: 'pointer',textDecoration: 'none'}} onClick={()=>{navigate('./')}}>
         Details
       </Link>,
        (location.pathname.includes('/verify-documents') ||  location.pathname.includes('/service-centers') || location.pathname.includes('/place-order')) && (
         <Typography key="2" color="text.primary" sx={{color: 'primary.main', cursor: 'pointer',textDecoration: 'none'}} onClick={()=>{navigate('./verify-documents')}}>
            Verify documents
         </Typography>
       ),
        (location.pathname.includes('/service-centers') || location.pathname.includes('/place-order')) && (
           <Typography key="2" color="text.primary" sx={{color: 'primary.main', cursor: 'pointer',textDecoration: 'none'}} onClick={()=>{navigate('./service-centers')}}>
            Service centers
           </Typography>
         ),
         ( location.pathname.includes('/place-order')) && (
           <Typography key="2" color="text.primary" sx={{color: 'primary.main', cursor: 'pointer',textDecoration: 'none'}}>
             Place order
           </Typography>
         )
     ];
   return (
       <div className="csc-list-header">
       <span className="header-back-icon">
         <span className="icon-back" onClick={backToCscCategoryList}><ArrowBackIosNewIcon /></span>
         <span className="header-text">
         <Breadcrumbs  separator={<NavigateNextIcon fontSize="small"  sx={{color:'primary.main'}}/>} aria-label="breadcrumb">
       {breadcrumbs}
     </Breadcrumbs>
            </span>
       </span>
       <span className="header-order">  {'Constable'} </span>
     </div>
   )
}

export default ApplicationCscCardHeader;