import * as React from 'react';
import ApplicationCscCardContainer from '../ApplicationCscCardContainer/ApplicationCscCardContainer.component';
import { Outlet } from 'react-router-dom';


  

const Services = () => {
  return (
    <>
   <Outlet/>
    </>
  );
}
export default Services;