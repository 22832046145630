import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const DeleteDialog = ({ setViewDeleteDialog, viewDeleteDialog, deleteAction , confirmationMessage, leftButtonTitle, rightButtonTitle}) => {
  console.log(confirmationMessage)
  const radioGroupRef = React.useRef(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };


  return (
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 535 } }}
        maxWidth="xs"
        TransitionProps={{ onEntering: handleEntering }}
        open={viewDeleteDialog}
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize: '0.9rem'}}>
        {confirmationMessage ?? `Are you sure you want to delete?`}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setViewDeleteDialog(false);
            }}
            sx={{fontSize: '0.7rem'}}
          >
            {leftButtonTitle ?? 'Cancel'}
          </Button>
          <Button
            onClick={() => {
             deleteAction();
              setViewDeleteDialog(false);
            }}
            sx={{color: 'error.main',fontWeight: 700, fontSize: '0.7rem'}}
          >
          {rightButtonTitle ?? 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default DeleteDialog;