import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user/userSlice";
import themeSlice from "./theme/themeSlice";

const store = configureStore({
    reducer: {
        user: userSlice,
        theme: themeSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})
export default store;