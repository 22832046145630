import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { createRoot } from 'react-dom/client';
export const showToastr = (type = 'success',message = 'Please try again') => {

    const toastrRoot = createRoot(
        document.getElementById("oa-toastr-container"))
  // pending -  to resolve multiple toastr message show in column

      setTimeout(()=>{
        toastrRoot.unmount()
      },5000)  
      toastrRoot.render(
            <Snackbar
            open={true}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            key={'top'+'right'}
            style={{width: 'fit-content', position: 'absolute'}}
          > 
             <MuiAlert  elevation={6}  severity={type} >
              {message}
            </MuiAlert>
          </Snackbar>  
        );

}