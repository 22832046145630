import { createSlice } from "@reduxjs/toolkit";

 const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        currentThemeMode: 'light'
    },
    reducers: {
        setThemeMode: (state, action) => {
              state.currentThemeMode = action.payload ;
        }
    }
})
export const {setThemeMode} = themeSlice.actions;
export default themeSlice.reducer;