import React from 'react';
import './PaymentOptions.styles.scss';

const PaymentOptions = () => {
  return (
    <div className="payment-options">
      <h2>Payment Options</h2>
      <div>
        <input type="radio" id="cod" name="payment" value="cod" />
        <label htmlFor="cod">Cash on Delivery</label>
      </div>
      <div>
        <input type="radio" id="credit" name="payment" value="credit" />
        <label htmlFor="credit">Credit Card</label>
      </div>
      <div>
        <input type="radio" id="debit" name="payment" value="debit" />
        <label htmlFor="debit">Debit Card</label>
      </div>
      <div>
        <input type="radio" id="netbanking" name="payment" value="netbanking" />
        <label htmlFor="netbanking">Net Banking</label>
      </div>
    </div>
  );
}

export default PaymentOptions;
