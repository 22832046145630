import React,{useEffect, useState} from 'react';
import './FilterApplications.styles.scss'
import EastIcon from '@mui/icons-material/East';
import Typography from '@mui/material/Typography';
import { APP_LABEL } from '../../translations';
import GeneralDialog from '../common/GeneralDialog/GeneralDialog.component';
import StateList from '../common/StateList/StateList.component';

const FilterApplications = ({ applicationCardListContainerRef }) => {
  const [age, setAge] = useState('s');
  const [openStateListDialog, setOpenStateListDialog] = useState(false)
  const [currentSelectedState, setCurrentSelectedState] = useState('')

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleServiceCategory = () => {
    applicationCardListContainerRef &&  applicationCardListContainerRef.current.scrollIntoView({ behavior: 'smooth' });
  }
 const handleServiceCategoryState =() => {
   setOpenStateListDialog(true)
 }
 useEffect(()=>{
   if(currentSelectedState?.length){
   setOpenStateListDialog(false)
   applicationCardListContainerRef &&  applicationCardListContainerRef.current.scrollIntoView({ behavior: 'smooth' });
   }
 },[currentSelectedState])
  return (
    <>
      <Typography className='oa-service-filter-heading' variant="h6" sx={{ color: 'secondary.contrastText' }}>{APP_LABEL['en'].FILTER_APP_HEADING}</Typography>

      <div className={'oa-filter-applications'}>

        <div className='oa-service-category-state' onClick={handleServiceCategoryState}>
          <Typography className='oa-service-category-text-header' sx={{ color: '#ffffff' }}> {APP_LABEL['en'].FILTER_APP_STATE_HEADING} </Typography>

          <Typography className='oa-service-category-text-header-description'> {APP_LABEL['en'].FILTER_APP_STATE_HEADING_DESC}  </Typography>
          <div className='oa-service-category-text-header-forward'>  <EastIcon sx={{ color: '#ffffff' }} /> </div>
        </div>
        <div className='oa-service-category-qualification' onClick={handleServiceCategory}>
          <Typography className='oa-service-category-text-header' sx={{ color: '#ffffff' }}> {APP_LABEL['en'].FILTER_APP_QUALIFICATION_HEADING} </Typography>

          <Typography className='oa-service-category-text-header-description'>{APP_LABEL['en'].FILTER_APP_QUALIFICATION_HEADING_DESC}</Typography>
          <div className='oa-service-category-text-header-forward'>  <EastIcon sx={{ color: '#ffffff' }} /> </div>
        </div>
        <div className='oa-service-category-category' onClick={handleServiceCategory}>
          <Typography className='oa-service-category-text-header' sx={{ color: '#ffffff' }}> {APP_LABEL['en'].FILTER_APP_POPULAR_CATEGORIES_HEADING} </Typography>

          <Typography className='oa-service-category-text-header-description'> {APP_LABEL['en'].FILTER_APP_POPULAR_CATEGORIES_HEADING_DESC}</Typography>
          <div className='oa-service-category-text-header-forward'>  <EastIcon sx={{ color: '#ffffff' }} /> </div>
        </div>
        <div className='oa-service-category-common-services' onClick={handleServiceCategory}>
          <Typography className='oa-service-category-text-header' sx={{ color: '#ffffff' }}> {APP_LABEL['en'].FILTER_APP_COMMON_SERVICE_HEADING} </Typography>

          <Typography className='oa-service-category-text-header-description'>  {APP_LABEL['en'].FILTER_APP_COMMON_SERVICE_HEADING_DESC} </Typography>
          <div className='oa-service-category-text-header-forward'>  <EastIcon sx={{ color: '#ffffff' }} /> </div>
        </div>
        {
        <GeneralDialog
        openDialog={openStateListDialog}
        setOpenDialog={setOpenStateListDialog}
        dialogHeader={'Select your state'}
        dialogBody={<StateList setCurrentSelectedState={setCurrentSelectedState}/>}
        />
      }
      </div>
      
    </>
  );
}
export default FilterApplications;