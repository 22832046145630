import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./PersonalDetails.styles.scss";
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
const PersonalDetails = ({userProfile, setUserProfile}) => {
    // const ALPHA_NUMERIC_DASH_REGEX = /^[0-9]$/;
    const [focus, setFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const handlePersonalDetailsForm = (e) => {
        setUserProfile( [ {
            personal: {
              ...userProfile[0].personal,
              [e.target.name]: e.target.value,
            },
            contacts: { ...userProfile[0].contacts },
          }])
    }
  return (
    <>
      <div className="form-container">
        <div className="person-form">
          <TextField
          name="fullName"
          onChange={handlePersonalDetailsForm}
          value={userProfile[0].personal.fullName}
            id="outlined-basic"
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              }
            }}
            fullWidth={true}
            label="Full name"
            variant="outlined"
          />
        </div>
        <div className="person-form">
        <FormLabel id="demo-radio-buttons-group-label" sx={{color: "primary.main"}}>Gender</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="gender"
             onChange={handlePersonalDetailsForm}
             value={userProfile[0].personal.gender}
            sx={{
                "&.PrivateSwitchBase-input": {
                    color: "red",
                  },    
    
               
            }}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                control={<Radio   sx={{
                    color: 'primary.main',
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  }}/>}
                label="Male"
                value="male"
              />
              <FormControlLabel
                value="female"
                control={<Radio  sx={{
                    color: 'primary.main',
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  }}/>}
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={<Radio  sx={{
                    color: 'primary.main',
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  }}/>}
                label="Other"
              />
            </div>
          </RadioGroup>
        </div>
        <div className="person-form">
          <TextField
           value={userProfile[0].personal.dob}
            fullWidth={true}
            name="dob"
            type={hasValue || focus ? "date" : "text"}
            
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue="12/08/1993"
            onChange={(e) => {
                handlePersonalDetailsForm(e)
              if (e.target.value) setHasValue(true);
              else setHasValue(false);
            }}
            sx={{
                "& .MuiFormLabel-root": {
                  color: "primary.main",
                }
              }}
            label="Date of birth"
            variant="outlined"
          />
        </div>
        <div className="person-form">
          <TextField
                onChange={handlePersonalDetailsForm}
            id="outlined-basic"
            label="Mobile number"
            name="mobile"
            value={userProfile[0].personal.mobile}
            type='phone'
             
            sx={{
                "& .MuiFormLabel-root": {
                  color: "primary.main",
                }
              }}

            //   onKeyDown={(event)=>{
            //     if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
            //         event.preventDefault();
            //       }
            //   }}
             
            fullWidth={true}
            variant="outlined"
          />
        </div>
        <div className="person-form">
        <Button
              fullWidth
            //   className="login-signup-button"
              variant="contained"
              color={"primary"}
              sx={{
                height: '2.5rem',
                fontSize: '1rem'
              }}
              onClick={()=>{
              }}
            //   onClick={handleUserAuthentication}
            //   endIcon={
            //     showLoader && (
            //       <>
            //         &nbsp; <CircularProgress color="inherit"  size={20} />
            //       </>
            //     )
            //   }
            >
             Save
            </Button>
            </div>
      </div>
    </>
  );
};
export default PersonalDetails;
