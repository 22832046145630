import React from 'react';
import { Button } from "@mui/material";

import './DocumentVerification.styles.scss';
import DocumentUploadAndVerification from '../common/DocumentUploadAndVerification/DocumentUploadAndVerification.component';
import { useNavigate } from 'react-router-dom';

const DocumentVerification = () => {
  const navigate = useNavigate();
  const proceedToCenter = () => {
    navigate('../service-centers');
  }
  return (
    <>
    <DocumentUploadAndVerification/>
     <div className='highlight' style={{ backgroundColor: '#e3e7f9' }}>
          <Button className='proceed-service-center-btn' size='small' variant='outlined' onClick={proceedToCenter} > Click here</Button> &nbsp; to check your nearby service centers.
        </div>
    </>
  );
};

export default DocumentVerification;
