import React from "react";
import TextField from "@mui/material/TextField";
import "./ContactDetails.styles.scss";
import Button from "@mui/material/Button";

const ContactDetails = ({ userProfile, setUserProfile }) => {
  const handleContactDetailsForm = (e) => {
    setUserProfile([
      {
        personal: {
          ...userProfile[0].personal,
        },
        contacts: { ...userProfile[0].contacts,  [e.target.name]: e.target.value },
        education: { ...userProfile[0].education },
        identity: {
            ...userProfile[0].identity
        },
      },
    ]);
  };
  return (
    <>
      <div className="form-container">
        <div className="identity-form">
          <TextField
            name="alternateNumber"
            onChange={handleContactDetailsForm}
            value={userProfile[0].contacts.alternateNumber}
            id="outlined-basic"
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            label="Alternate Mobile"
            variant="outlined"
          />
        </div>

        <div className="identity-form">
          <TextField
            onChange={handleContactDetailsForm}
            id="outlined-basic"
            label="Alternate email"
            name="alternateEmail"
            value={userProfile[0].contacts.alternateEmail}
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            variant="outlined"
          />
        </div>

        <div className="identity-form">
          <TextField
            onChange={handleContactDetailsForm}
            id="outlined-basic"
            label="Address"
            name="currentAddress"
            value={userProfile[0].contacts.currentAddress}
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            variant="outlined"
          />
        </div>









        <div className="identity-form">
          <TextField
            onChange={handleContactDetailsForm}
            id="outlined-basic"
            label="District/City"
            name="cityDistrict"
            value={userProfile[0].contacts.cityDistrict}
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            variant="outlined"
          />
        </div>

        <div className="identity-form">
          <TextField
            onChange={handleContactDetailsForm}
            id="outlined-basic"
            label="Pincode"
            name="pinCode"
            value={userProfile[0].contacts.pinCode}
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            variant="outlined"
          />
        </div>


        <div className="identity-form">
          <TextField
            onChange={handleContactDetailsForm}
            id="outlined-basic"
            label="State"
            name="state"
            value={userProfile[0].contacts.state}
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            variant="outlined"
          />
        </div>

        <div className="identity-form">
          <TextField
            onChange={handleContactDetailsForm}
            id="outlined-basic"
            label="Country"
            name="country"
            value={userProfile[0].contacts.country}
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            variant="outlined"
          />
        </div>

        <div className="identity-form">
          <Button
            fullWidth
            variant="contained"
            color={"primary"}
            sx={{
              height: "2.5rem",
              fontSize: "1rem",
            }}
            onClick={() => {
            }}
            //   onClick={handleUserAuthentication}
            //   endIcon={
            //     showLoader && (
            //       <>
            //         &nbsp; <CircularProgress color="inherit"  size={20} />
            //       </>
            //     )
            //   }
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;

