import React from "react";
import TextField from "@mui/material/TextField";
import "./IdentityDetails.styles.scss";
import Button from "@mui/material/Button";

const IdentityDetails = ({ userProfile, setUserProfile }) => {
  const isAadharValid = (aadharNumber) => {
    const regex  = /^\d{4}\s\d{4}\s\d{4}$/;
    if (!aadharNumber){
      return false;
   }
   if(regex.test(aadharNumber)){
    return true;
  } else {
    return false;
  }
}
  const handleIdentityDetailsForm = (e) => {
    isAadharValid(e.target.value);
    setUserProfile([
      {
        personal: {
          ...userProfile[0].personal,
        },
        contacts: { ...userProfile[0].contacts },
        education: { ...userProfile[0].education },
        identity: {
          ...userProfile[0].identity,
          [e.target.name]: e?.target?.value,
        },
      },
    ]);
  };

  return (
    <>
      <div className="form-container">
        <div className="identity-form">
          <TextField
            name="aadharNumber"
            onChange={handleIdentityDetailsForm}
            inputProps={{ maxLength: 14 }}
            value={userProfile[0]?.identity?.aadharNumber}
            id="outlined-basic"
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            label="AADHAR"
            variant="outlined"
            
            placeholder="XXXX XXXX XXXX"
          />
        </div>

        <div className="identity-form">
          <TextField
            onChange={handleIdentityDetailsForm}
            id="outlined-basic"
            label="PAN"
            name="panNumber"
            value={userProfile[0].identity.panNumber}
            sx={{
              "& .MuiFormLabel-root": {
                color: "primary.main",
              },
            }}
            fullWidth={true}
            variant="outlined"
          />
        </div>
        <div className="identity-form">
          <Button
            fullWidth
            variant="contained"
            color={"primary"}
            sx={{
              height: "2.5rem",
              fontSize: "1rem",
            }}
            onClick={() => {
            }}
            //   onClick={handleUserAuthentication}
            //   endIcon={
            //     showLoader && (
            //       <>
            //         &nbsp; <CircularProgress color="inherit"  size={20} />
            //       </>
            //     )
            //   }
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
export default IdentityDetails;
