export const userDisplayName = (displayName) => {
  return (
    displayName
      ?.match(/\b(\w)/g)
      .join("")
      .toUpperCase() || ""
  );
};
export const getChipColor = (value) => {
    if(value.includes('progress')) {
         return 'warning';
    } else if(value.includes('Applied')) {
      return 'info';
    } else if(value.includes('Cancelled')) {
      return 'error';
    } else {
      return 'success';
    }
}
