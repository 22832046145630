import React from 'react';
import './CustomerReview.styles.scss';
import customerReviewImage from '../../../src/assets/images/customer_review.png'
import Rating from '@mui/material/Rating';
const CustomerReview = () => {
    return(
        <>
         <div className="oa-cs-container">
        <h2 className='heading'> SOME WORDS FROM OUR HAPPY CUSTOMERS! </h2>
     
    
    
        <div className="reviewSection">

     
            <div className="reviewItem">
                <div className="top">
                    <div className="clientImage">
                        <img src={customerReviewImage} alt="Customer image"/>
                        <span>Rahul Jangra</span>
                    </div>
                       <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly size="small" />
                </div>
                <article>
                    <p className="review">I recently used the online application form on online applications, and I must say, it was incredibly convenient and straightforward. The entire process from start to finish was smooth and user-friendly.</p>
                    <p>Mar 23, 2024</p>
                </article>
            </div>

            <div className="reviewItem">
                <div className="top">
                    <div className="clientImage">
                    <img src={customerReviewImage} alt="Customer image"/>
                        <span>Amit Verma</span>
                    </div>
                       <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly size="small" />
                </div>
                <article>
                    <p className="review">Overall, my experience with online application form was excellent. It saved me time and effort, and I would definitely recommend it to anyone looking for a hassle-free way to submit applications online.</p>
                    <p>Jan 10, 2024</p>
                </article>
            </div>

     
            <div className="reviewItem">
                <div className="top">
                    <div className="clientImage">
                    <img src={customerReviewImage} alt="Customer image"/>
                        <span>Ankush Saharan</span>
                    </div>
                       <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly size="small" />
                </div>
                <article>
                    <p className="review">What impressed me the most was the responsiveness of the platform. It worked flawlessly on both desktop and mobile devices, which made it super convenient for me to fill out the form on the go.</p>
                    <p>Feb 11, 2024</p>
                </article>
            </div>
        </div>
    </div>
        </>
    )
}
export default CustomerReview;