import React from 'react';
import AppFooter from './AppFooter/AppFooter.component';
import AppHeadings from './AppHeadings/AppHeadings.component';
import { Outlet } from 'react-router-dom';
import { FirebaseAuth } from '../../auth/FirebaseAuth';

const AppLayout = () => {
  return (
    <>
    <AppHeadings/>
    <div style={{marginTop: '5rem'}}>
    <FirebaseAuth>
     <Outlet/>
     </FirebaseAuth>
     </div>
    <AppFooter/>
    </>
  )
}
export default AppLayout;