export const isUserAuthenticated = () => {
     const user = JSON.parse(localStorage.getItem('userProfile'));
     return user ? true : false;
}
export const getLoggedInUserInfo = () => {
    const user = JSON.parse(localStorage.getItem('userProfile'));
    return user;
} 
export const getUserDisplayName = () => {
    const user = JSON.parse(localStorage.getItem('userDisplayName'));
    return user;
} 