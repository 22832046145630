export const applicationDetails = [{
    "orderId": '159',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card 159',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '237',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '2621',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '3052',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '356',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '199959',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '2371',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '2622',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '305',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '3561',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '159999999',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '2372',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '262',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '3051',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
},
{
    "orderId": '3562',
    'applicationDetails':
    {

        "applicationName": 'Aadhar card',
        "applicationStatus": 'Completed',
        "amount": 200,
        "applicationSubmissionDate": '01/01/2024',
        "applicationLastUpdatedDate": '21/04/2024',
        "applicationFinalDate": '21/05/2024',


    },
    'cscCenterDetails': {
        cscCenterName: 'Jain Center',
        cscCenterAddress: 'Rishi nagar,125004,Hisar,Haryana ',
        cscCenterMobile: '9729578654',
        cscCenterEmail: 'csc@gmail.com',
    },
    'docuemntAccessList': {
        'documentList': ['Aadhar', 'Pan', '10th Marksheet'],
    },
    'actions': {
        'removeDocumentAccress': 'Yes/No',
        'cancelApplication': 'Yes/No'
    }
}


]
export const applicationDetailsHeaderMapping = {
    orderId: 'Order Id',
    applicationDetails: 'Application Details',
    cscCenterDetails: 'CSC Center Details',
    docuemntAccessList: 'Docuemnt Access List',
    actions: 'Actions'
}


export const applicationDetailMapping = {
    "applicationName": 'Name',
    "applicationStatus": 'Status',
    "amount": 'Application Fees(₹)',
    "applicationSubmissionDate": 'Submission Date',
    "applicationLastUpdatedDate": 'Last Updated On',
    "applicationFinalDate": 'Final Date',
    "cscCenterName": 'Name',
    'cscCenterAddress': 'Address',
    'cscCenterMobile': 'Mobile',
    'cscCenterEmail': 'Email',
    'documentList': 'Documents',
    'removeDocumentAccress': 'Remove Document',
    'cancelApplication': 'Cancel Application'
}
