import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ApplicationsDetails.styles.scss';
import Button from '@mui/material/Button';
import LoginModal from '../LoginModal/LoginModal.component';
const ApplicationDetails = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const loginFromAppDetailsPage = () => {
    setOpen(true);
  }
  const proceedToDocumentVerification = () => {
    navigate(`./verify-documents`);
  }
  const isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
  const selectionCriteriaListItems = [
    {
      id: '1',
      phaseName: 'Objective test',
      phaseDesc: 'There will be a written exam of 100 optional MCQ.'
    },
    {
      id: '2',
      phaseName: 'Skill Test',
      phaseDesc: 'There will be a skill exam kind of practical exam.'
    },
    {
      id: '3',
      phaseName: 'Interview',
      phaseDesc: 'There will be a interview exam face to face.'
    },
    {
      id: '4',
      phaseName: 'Document Verification',
      phaseDesc: 'There will be a document verification for final qualified candidates.'
    }

  ]
  return (
    <>
      <div style={{ padding: '1rem' }}>
        <div className='highlight' style={{ backgroundColor: '#f3f4f9' }}>
         {
          Boolean(!isUserLoggedIn) ?
        <>  <Button sx={{ textTransform: 'none', borderRadius: '4rem', ':hover': { cursor: 'pointer' } }} size='small' variant='outlined' onClick={loginFromAppDetailsPage} > Login</Button> &nbsp; to apply for this service/job.</>
          :
          <> <Button sx={{ textTransform: 'none', borderRadius: '4rem', ':hover': { cursor: 'pointer' } }} size='small' variant='outlined' onClick={proceedToDocumentVerification} > Click here</Button> &nbsp; to check your eligibility,verify documents to apply for this service/job.</>
}
        </div>
        <Accordion defaultExpanded>
          <AccordionSummary
            className='app-details-accordon-summary'
            expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.main' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: 'primary.main', minHeight: '0.5rem' }}
          >
            <Typography sx={{ color: 'secondary.main', fontSize: '1.25rem' }}>Important Dates</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '1.5rem', backgroundColor: '#f3f4f9' }}>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Start Date for Apply Online Form:&nbsp;
              </Typography>
              <Typography>
                12/12/2024
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Last Date for Apply Online Form:&nbsp;
              </Typography>
              <Typography sx={{ color: 'red' }}>
                12/12/2024
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Exam Date:&nbsp;
              </Typography>
              <Typography>
                12/1/2025
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Admit Card Available:&nbsp;
              </Typography>
              <Typography>
                Before Exam
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            className='app-details-accordon-summary'
            expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.main' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: 'primary.main', minHeight: '0.5rem' }}
          >
            <Typography sx={{ color: 'secondary.main', fontSize: '1.25rem' }}>Age</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '1.5rem', backgroundColor: '#f3f4f9' }}>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Minimum age: &nbsp;
              </Typography>
              <Typography>
                25 years
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Maximum age: &nbsp;
              </Typography>
              <Typography>
                40 years
              </Typography>
            </div>
            <Typography sx={{ fontSize: '1rem', fontWeight: '700', marginTop: '0.5rem' }}>
              Age Relaxation As Per Rules.(Please refer official notification for more info)
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            className='app-details-accordon-summary'
            expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.main' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: 'primary.main', minHeight: '0.5rem' }}
          >
            <Typography sx={{ color: 'secondary.main', fontSize: '1.25rem' }}>Application fee</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '1.5rem', backgroundColor: '#f3f4f9' }}>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                General/Un-reserved(UR):&nbsp;
              </Typography>
              <Typography>
                &#8377;200
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                EWS:&nbsp;
              </Typography>
              <Typography>
                &#8377;199
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                OBC:&nbsp;
              </Typography>
              <Typography>
                &#8377;199
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                SC/ST:&nbsp;
              </Typography>
              <Typography>
                &#8377;199
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            className='app-details-accordon-summary'
            expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.main' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: 'primary.main', minHeight: '0.5rem' }}
          >
            <Typography sx={{ color: 'secondary.main', fontSize: '1.25rem' }}>Selection Criteria</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '1.5rem', backgroundColor: '#f3f4f9' }}>

            <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
              There will be total {selectionCriteriaListItems?.length} phase(s) <br /> <br />
            </Typography>

            {
              selectionCriteriaListItems.map((item, index) => {
                return (
                  <div style={{ marginBottom: '0.5rem' }}>
                    <Typography key={item?.id} sx={{ fontSize: '1rem', fontWeight: '700' }}>
                      Phase {index + 1}:  {item?.phaseName}
                    </Typography>

                    <Typography key={item?.id} >
                      Description:                        {item?.phaseDesc}
                    </Typography>

                  </div>
                )
              })
            }



          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            className='app-details-accordon-summary'
            expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.main' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: 'primary.main', minHeight: '0.5rem' }}
          >
            <Typography sx={{ color: 'secondary.main', fontSize: '1.25rem' }}>Official Links</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '1.5rem', backgroundColor: '#f3f4f9' }}>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Notification:&nbsp;
              </Typography>
              <Typography>
                Download
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
              <Typography sx={{ fontSize: '1rem', fontWeight: '700' }}>
                Website:&nbsp;
              </Typography>
              <Typography>
                www.isro.com
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {open &&
      <LoginModal openLoginModal={open} setOpenLoginModal={setOpen}/> }

    </>
  )
}

export default ApplicationDetails;