export const rowsData = [
    {

        "orderId": '159',
        "serviceName": 'Aadhar card',
        "serviceStatus": 'Completed',
       "serviceLastDate": '21/04/2024',
       "amount": 200,
       // "cscCenter": "XYZ center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '237',
        "serviceName": 'Pan Card',
        "serviceStatus": 'Cancelled',
       "serviceLastDate": '02/04/2024',
       "amount": 1500,
       // // "cscCenter": "Jangra center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '2621',
        "serviceName": 'Haryana Polic Form',
        "serviceStatus": 'Completed',
       "serviceLastDate": '11/03/2023',
       "amount": 50,
       // "cscCenter": "City center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '305',
        "serviceName": 'IIT-JEE form',
        "serviceStatus": 'Applied',
       "serviceLastDate": '05/04/2022',
       "amount": 40,
       // "cscCenter": "Rishi center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '356',
        "serviceName": 'NEET Form',
        "serviceStatus": 'Completed',
       "serviceLastDate": '04/01/2022',
       "amount": 100,
       // "cscCenter": "Jain center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '199959',
        "serviceName": 'Aadhar card',
        "serviceStatus": 'Completed',
       "serviceLastDate": '21/04/2024',
       "amount": 200,
       // "cscCenter": "XYZ center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '2371',
        "serviceName": 'Pan Card',
        "serviceStatus": 'In-progress',
       "serviceLastDate": '02/04/2024',
       "amount": 1500,
       // "cscCenter": "Jangra center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '2622',
        "serviceName": 'Haryana Polic Form',
        "serviceStatus": 'Completed',
       "serviceLastDate": '11/03/2023',
       "amount": 50,
       // "cscCenter": "City center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '3051',
        "serviceName": 'IIT-JEE form',
        "serviceStatus": 'Applied',
       "serviceLastDate": '05/04/2022',
       "amount": 40,
       // "cscCenter": "Rishi center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '3561',
        "serviceName": 'NEET Form',
        "serviceStatus": 'Completed',
       "serviceLastDate": '04/01/2022',
       "amount": 100,
       // "cscCenter": "Jain center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '159999999',
        "serviceName": 'Aadhar card',
        "serviceStatus": 'Completed',
       "serviceLastDate": '21/04/2024',
       "amount": 200,
       // "cscCenter": "XYZ center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {
 
        "orderId": '2372',
        "serviceName": 'Pan Card',
        "serviceStatus": 'In-progress',
       "serviceLastDate": '02/04/2024',
       "amount": 1500,
       // "cscCenter": "Jangra center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {

        "orderId": '262',
        "serviceName": 'Haryana Polic Form',
        "serviceStatus": 'Completed',
       "serviceLastDate": '11/03/2023',
       "amount": 50,
       // "cscCenter": "City center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {
  
        "orderId": '3052',
        "serviceName": 'IIT-JEE form',
        "serviceStatus": 'Applied',
       "serviceLastDate": '05/04/2022',
       "amount": 40,
       // "cscCenter": "Rishi center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    },
    {
        "orderId": '3562',
        "serviceName": 'NEET Form',
        "serviceStatus": 'Completed',
       "serviceLastDate": '04/01/2022',
       "amount": 100,
       // "cscCenter": "Jain center",
       "lastUpdated": "23/01/2024",
       "actions": ''
    }
]

export const columnData = [ 'Order ID', 'Service', 'Status', 'Final Date', 'Amount(₹)', 'Last Updated On', 'Actions']