import { createSlice } from "@reduxjs/toolkit";

 const userSlice = createSlice({
    name: 'user',
    initialState: {
        isUserLoggedIn: false,
        loggedInUserInfo: {},
        userDisplayName: ''
    },
    reducers: {
        setUserLogInState: (state, action) => {
              state.isUserLoggedIn = action.payload ;
        },
        setLoggedInUserInfo: (state, action) => {
             state.loggedInUserInfo = action.payload;
        },
        setUserDisplayName: (state, action) => {
            state.userDisplayName = action.payload;
        }
    }
})
export const {setUserLogInState, setLoggedInUserInfo, setUserDisplayName} = userSlice.actions;
export default userSlice.reducer;