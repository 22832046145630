import {React} from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';

const Actions = ({row}) => {
    const actionsStyles = { color: "primary.main", fontSize: 20 }
    return (
      <Link to={`order/${row['orderId']}/details`}>
      <OpenInNewIcon
      sx={actionsStyles}
      className="file-icons-hover"
    />
    </Link>
    )
  }
  export default Actions;