import * as React from 'react';
import './ChooseUs.styles.scss'
import Typography from '@mui/material/Typography';
import queueIcon from "../../../src/assets/svg/Queue up wait line up (1).svg";
import lowPrice from "../../../src/assets/svg/Online shopping credit card payment.svg";
import easyApply from "../../../src/assets/svg/Rocket space astronomy spaceship satellite.svg";

import { APP_LABEL } from '../../translations/index';

const ChooseUs =() => {

  // svg icon website https://iamvector.com/icons?search=queue
  return (
    <>
      <div className='oa-service-choose-cards'>
      <Typography
              variant="h6"
              gutterBottom
              sx={{color:'secondary.contrastText',padding: '2rem',fontWeight: 600}}
            >
            {APP_LABEL['en'].CHOOSE_US_HEADING}
            </Typography>
        <div className='oa-choose-card-container'>
          <div className='oa-choose-card'>
            <img src={queueIcon} alt='Easy Apply' width={250}  height={180}/>
            <Typography
              variant="h6"
              gutterBottom
              sx={{fontWeight: 600, margin: '0.5rem'}}
            >
              {APP_LABEL['en'].CHOOSE_US_NO_QUEUE}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{fontSize: '1rem', padding: '1rem'}}
            >
                {APP_LABEL['en'].CHOOSE_US_NO_QUEUE_DESCRIPTION}
               </Typography>
          
          </div>
          <div className='oa-choose-card'>
            <img src={lowPrice} alt='Easy Apply' width={250}  height={180}/>
            <Typography
              variant="h6"
              gutterBottom
              sx={{fontWeight: 600, margin: '0.5rem'}}
            >
             {APP_LABEL['en'].CHOOSE_US_LOW_CHARGES}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{fontSize: '1rem', padding: '1rem'}}
            >
                 {APP_LABEL['en'].CHOOSE_US_LOW_CHARGES_DESCRIPTION}
               </Typography>
          
          </div>
          <div className='oa-choose-card'>
            <img src={easyApply} alt='Easy Apply' width={250}  height={180}/>
            <Typography
              variant="h6"
              gutterBottom
              sx={{fontWeight: 600, margin: '0.5rem'}}
            >
             {APP_LABEL['en'].CHOOSE_US_EASY_APPLY}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{fontSize: '1rem', padding: '1rem'}}
            >
                {APP_LABEL['en'].CHOOSE_US_EASY_APPLY_DESCRIPTION}
               </Typography>
          
          </div>
        

        </div>
    </div>
   
    </>
  );
}
export default ChooseUs;