import React from "react";
import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";

import "./AppMobileDrawer.styles.scss";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo.component";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
const AppMobileDrawer = (props) => {
  const {
    navigationItems,
    toggleAppMobileDrawer,
    setToggleAppMobileDrawer,
    mode,
    setMode,
  } = props;

  return (
    <React.Fragment>
      <Drawer
        transitionDuration={700}
        PaperProps={{
          sx: {
            height: "calc(100% - 55px)",
            top: 55,
            boxShadow: 0,
          },
        }}
        elevation={0}
        containerstyle={{ height: "calc(100% - 64px)", top: 64 }}
        anchor="left"
        overlaystyle={{ backgroundColor: "transparent" }}
        open={toggleAppMobileDrawer}
        variant="persistent"
        onClose={() => setToggleAppMobileDrawer(false)}
      >
        <div
          className="drawer-logo"
          style={{ backgroundColor: "background.default" }}
        >
          {" "}
          <Logo />
        </div>
        <Divider />
        <List
          sx={{
            backgroundColor: "background.default",
            width: "100vw",
            paddingTop: "0 !important",
            height: "100vh",
          }}
        >
          {navigationItems.map((navItem, index) => (
            <ListItemButton key={index} className="list-items">
              <Link to={navItem.to} onClick={() => setToggleAppMobileDrawer(false)}>
                <ListItemIcon
                  className="list-items-icon"
                  sx={{ color: "primary.main" }}
                >
                  {navItem.icon} &nbsp;
                  <ListItemText
                    className="list-items-text"
                    sx={{ color: "primary.main" }}
                  >
                    {navItem.name}
                  </ListItemText>
                </ListItemIcon>
              </Link>
            </ListItemButton>
          ))}
        </List>
        <MenuItem
          sx={{
            width: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
          }}
          onClick={() => {
            if( mode === 'light') {
              localStorage.setItem("userTheme", "dark");
            } else {
              localStorage.setItem("userTheme", "light");
            }

            setMode((prev) => (prev === "light" ? "dark" : "light"));
            setToggleAppMobileDrawer(false)
          }}
        >
          <ListItemIcon
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            {mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
          </ListItemIcon>
         {mode === 'light' ? 'Dark' : 'Light'}
        </MenuItem>
      </Drawer>
    </React.Fragment>
  );
};

export default AppMobileDrawer;