import React from 'react';
import './LoginDetails.styles.scss';

const LoginDetails = () => {
  return (
    <div className="login-details">
      <h2>Login Details</h2>
      <p>User: John Doe</p>
      <p>Email: john.doe@example.com</p>
    </div>
  );
}

export default LoginDetails;
