import React, { useState, useContext } from "react";
import "./Login.styles.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import googleIcon from "../../../../src/assets/svg/googleIcon.svg";
import {
  checkSignupFormValidation,
  hasErrorInSIgnupForm,
} from "../../../utils/formValidations";
import IconButton from "@mui/material/IconButton";
import { FirebaseAuthContext } from "../../../auth/FirebaseAuth";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/Logo/Logo.component";
import { showToastr } from "../../../components/ToastrMessage/Toast.helper";
import { APP_LABEL } from "../../../translations";
import { useDispatch } from "react-redux";
import { setLoggedInUserInfo, setUserDisplayName, setUserLogInState } from "../../../store/user/userSlice";

const Login = ({ setOpen, setIsUserLoggedIn, setUserInfo, setDisplayName }) => {
  const [isSignInForm, setIsSignInForm] = useState(true);
  const [isForgotPasswordForm, setIsForgotPasswordForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [showRePassword, setReShowPassword] = useState(false); confirm pass code commented for now
  const {
    signUpUser,
    signInUser,
    resetPassword,
    updateUserProfile,
    signInWithGooglePopup,
  } = useContext(FirebaseAuthContext);
  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    password: "",
    rePassword: "",
  });
  const [userDetailsError, setUserDetailsError] = useState({
    username: "",
    email: "",
    password: "",
    // rePassword: "",
  });
  const handleFormChange = (e) => {
    setUserDetails((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleClickReShowPassword = () => setReShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // const handleMouseDownRePassword = (event) => {
  //   event.preventDefault();
  // };
  const setUserInfoToStore = (authState, userName, userInfo) => {
    localStorage.setItem("userProfile", JSON.stringify(userInfo));
    localStorage.setItem("userDisplayName", JSON.stringify(userName));
    localStorage.setItem("isUserLoggedIn", authState);
    setIsUserLoggedIn(authState);
    setUserInfo(userInfo);
    setDisplayName(userName);
    dispatch(setUserLogInState(authState))
    dispatch(setLoggedInUserInfo(authState, userInfo));
    dispatch(setUserDisplayName(userName))

  };
  const resetLoginFormError = () => {
    setUserDetailsError({
      username: "",
      email: "",
      password: "",
      rePassword: "",
    });
    setUserDetails((prev) => {
      return {
        ...prev,
        username: "",
        password: "",
        rePassword: "",
      };
    });
  };
  const handleLoginSignupAction = () => {
    setIsSignInForm(!isSignInForm);
    resetLoginFormError();
  };

  const handleUserAuthentication = async () => {
    const errorMessage = checkSignupFormValidation(userDetails);
    if (!isSignInForm && !isForgotPasswordForm) {
      setUserDetailsError(errorMessage);
      if (!hasErrorInSIgnupForm(errorMessage)) {
        setShowLoader(true);
        await signUpUser(userDetails.email, userDetails.password)
          .then((data) => {
            updateUserProfile(userDetails.username).then(() => {
              setUserInfoToStore(true, data?.user?.displayName, data?.user);
              setShowLoader(false);
            });
            setOpen(false);
          })
          .catch((error) => {
            setShowLoader(false);
            if (error.code === 'auth/email-already-in-use') {
              setUserDetailsError({
                email: "Email already in use, try with another email",
              });
            }
            if (error.code.includes("password")) {
              setUserDetailsError({ password: error.message });
            }
            if (error.code === "auth/network-request-failed") {
              setShowLoader(false)
              showToastr('error',APP_LABEL['en'].ERROR_NO_INTERNET_CONNECTION)
              
            }
            setOpen(true);
          });
      }
    } else if (isSignInForm && !isForgotPasswordForm) {

      setUserDetailsError(errorMessage);
      setUserDetailsError({
        ...errorMessage,
        password: !userDetails.password ? "Password is incorrect" : '',
      });
      if (errorMessage.email || !userDetails.password) {
        return;
      }
      setShowLoader(true);
      signInUser(userDetails.email, userDetails.password)
        .then((data) => {
          setShowLoader(false);
          setOpen(false);
          setUserInfoToStore(true, data?.user?.displayName, data?.user);
          navigate('/');
        })
        .catch((error) => {
          setShowLoader(false); 
          // Client side handled -- we can remove after testing
          // if (error.message.includes("email")) {
          //   setUserDetailsError({
          //     email: "Please enter valid email address",
          //   });
          // }
          // if (error.message.includes("password")) {
          //   setUserDetailsError({ password: "Password is incorrect" });
          // }
          if (error.code === "auth/invalid-login-credentials") {
            setUserDetailsError({
              email: "Inavlid login details",
            });
          }
          if (error.code === "auth/network-request-failed") {
            setShowLoader(false)
            showToastr('error',APP_LABEL['en'].ERROR_NO_INTERNET_CONNECTION)
            
          }
          if(error?.code === 'auth/too-many-requests') {
            showToastr('error',APP_LABEL['en'].ERROR_ACCOUNT_DISABLED)
          }
          
        });
    } else {

      setUserDetailsError(errorMessage);
      if (errorMessage.email) {
        return;
      }
      setShowLoader(true);
      resetPassword(userDetails.email)
        .then((data) => {
          resetLoginFormError();
          setIsResetSuccess(true);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
          // Client side handled -- we can remove after testing
          // if (error.code === 'auth/missing-email') {
          //   setUserDetailsError({
          //     email: "Please enter valid email address",
          //   });
          // }
          if (error.code === "auth/network-request-failed") {
             setShowLoader(false)
             showToastr('error',APP_LABEL['en'].ERROR_NO_INTERNET_CONNECTION)
            
            
          }
        });
    }
  };
  const signInWithGoogle = () => {
    resetLoginFormError();
    signInWithGooglePopup()
      .then((data) => {
        setUserInfoToStore(true, data?.user?.displayName, data?.user);
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="login-container" sx={{ backgroundColor: "primary.main" }}>
      <div className="left-login-container">
        {" "}
      <Logo width={'300px'} />
      </div>
      <div className="right-login-container">
        <span className="login-modal-cross">
          <CloseIcon
            onClick={() => setOpen(false)}
            className="cross-icon"
            sx={{ color: "text.primary" }}
          />
        </span>
        {!isSignInForm && !isForgotPasswordForm && (
          <TextField
            id="name"
            name="username"
            required
            fullWidth
            InputLabelProps={{
              style: { color: "#2B366E" },
            }}
            error={userDetailsError.username ? true : false}
            helperText={userDetailsError.username}
            label="Full name"
            value={userDetails.username}
            onChange={handleFormChange}
            variant="standard"
          />
        )}
        {isForgotPasswordForm && !isResetSuccess && (
          <>
            <br />
            <Typography
              variant="h6"
              gutterBottom
              className="reset-password-heading"
            >
              Enter your email address to reset password
            </Typography>
          </>
        )}
        {isResetSuccess && (
          <>
            <br />
            <p className="reset-password-heading">
              {`If an account exists for ${userDetails.email}, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.`}
            </p>
          </>
        )}
        {!isSignInForm && !isForgotPasswordForm && (
          <>
            <br />
            <br />
          </>
        )}
        {!isResetSuccess && (
          <>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email address"
              InputLabelProps={{
                style: { color: "#2B366E" },
              }}
              required={isSignInForm ? false : true}
              error={userDetailsError.email ? true : false}
              helperText={userDetailsError.email}
              value={userDetails.email}
              onChange={handleFormChange}
              variant="standard"
            />{" "}
            <br />
            <br />
          </>
        )}
        {!isForgotPasswordForm && (
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            InputLabelProps={{
              style: { color: "#2B366E" },
            }}
            required={isSignInForm ? false : true}
            error={userDetailsError.password ? true : false}
            helperText={userDetailsError.password}
            type={showPassword ? "text" : "password"}
            value={userDetails.password}
            onChange={handleFormChange}
            autoComplete="current-password"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOff color="primary" />
                    ) : (
                      <Visibility color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}{" "}
        {!isForgotPasswordForm && <br />}
        {!isForgotPasswordForm && (
          <>
            {isSignInForm ? (
              <>
                <br />
                <span className="forgot-password">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    onClick={() => {
                      setIsForgotPasswordForm(!isForgotPasswordForm);
                      setIsSignInForm(false);
                      resetLoginFormError();
                    }}
                  >
                    Reset Password?
                  </Typography>
                </span>
              </>
            ) : (
              <>
                {" "}
                <br />
                <br />
              </>
            )}
          </>
        )}
        {!isResetSuccess && (
          <>
            <Button
              fullWidth
              className="login-signup-button"
              variant="contained"
              color={"primary"}
              onClick={handleUserAuthentication}
              endIcon={
                showLoader && (
                  <>
                    &nbsp; <CircularProgress color="inherit"  size={20} />
                  </>
                )
              }
            >
              {isSignInForm ? (
                showLoader ? (
                "Logging in" 
                ) : (
                  "Login"
                )
              ) : !isForgotPasswordForm ? (
                showLoader ? (
                  "Signing up"
                ) : (
                  "Sign up"
                )
              ) : showLoader ? (
                <>Sending</>
              ) : (
                "Reset Password"
              )}
            </Button>
          </>
        )}
        <br />
        {isForgotPasswordForm && (
          <div className="cancel-forgot-password">
            {" "}
            <Typography
              variant="subtitle2"
              className="cancel-title"
              gutterBottom
              onClick={() => {
                setIsForgotPasswordForm(false);
                setIsSignInForm(true);
                resetLoginFormError();
                setIsResetSuccess(false);
              }}
            >
              {isResetSuccess ? "Back to Login" : "Cancel"}
            </Typography>
          </div>
        )}
        {!isForgotPasswordForm && (
          <>
            {isSignInForm ? (
              <div className="no-account-heading">
                <Typography variant="body2" gutterBottom>
                  Don't have an account?&nbsp;
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="no-account-text"
                  gutterBottom
                  onClick={handleLoginSignupAction}
                >
                  Sign up
                </Typography>
              </div>
            ) : (
              <div className="no-account-heading">
                <Typography variant="" gutterBottom>
                  Already have an account?&nbsp;
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="no-account-text"
                  gutterBottom
                  onClick={handleLoginSignupAction}
                >
                  Login
                </Typography>
              </div>
            )}
          </>
        )}
        {!isForgotPasswordForm && (
          <>
            {isSignInForm && (
              <p className="or-login-option">
                <span>OR</span>
              </p>
            )}
            {isSignInForm && (
              <>
                <br />
                <div className="login-identifier-section">
                  <Button
                    fullWidth
                    color={"primary"}
                    variant="standard"
                    onClick={signInWithGoogle}
                    className="login-identifier-button"
                    startIcon={
                      <img
                        src={googleIcon}
                        alt="google-login"
                        width={20}
                        height={20}
                      />
                    }
                  >
                    &nbsp;{" "}
                    <Typography
                      variant="button"
                      style={{ textTransform: "none" }}
                      display="block"
                      gutterBottom
                    >
                      Login with Google
                    </Typography>
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Login;
