export const applicationsWithQualification =
    [{
        applicationCategory: 'qualification',
        applicationsList: [{
            applicationsCategoryName: '10th',
            applicationsListByCategory: [
                {
                    applicationName: "Clerk",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Peon",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "MTS",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Constable",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Assistant",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Clerk",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Peon",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "MTS",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Constable",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Assistant",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                }
            ]
        },
        {
            applicationsCategoryName: '12th',
            applicationsListByCategory: [
                {
                    applicationName: "Clerk-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Peon-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "MTS-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Constable-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Assistant-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Clerk-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Peon-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "MTS-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Constable-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Assistant-12",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                }
            ]
        },
        {
            applicationsCategoryName: 'Graduation',
            applicationsListByCategory: [
                {
                    applicationName: "Clerk-Graduation clerk gardd efr rfr",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Peon-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "MTS-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Constable-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Assistant-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Clerk-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Peon-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "MTS-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Constable-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                },
                {
                    applicationName: "Assistant-Graduation",
                    applicationStartDate: "Sept 14, 2024",
                    applicationImageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq6sYyW3nIRd6e2ghFLSPdyzFt2AqMC6Go_A&s",
                    applicationDescription: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests",
                    applicationLastDate: "Nov 14, 2024"
                }
            ]
        }


        ]
    }]