import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { IMAGE_EXTENSION_TYPE } from '../../../../constants/appConfig';
// import { Worker, Viewer } from '@react-pdf-viewer/react-pdf';

const Fileviewdialog  =({open, setOpen, currentSelectedFile, downloadFile}) => {
  const fileName = currentSelectedFile?.file?.name;

 const handleCancel = () => {
    setOpen(false);
 }
    return (
        <>
           <Dialog
      sx={{ '& .MuiDialog-paper': { width: '100%', height: '100%' } }}
    //   maxWidth="xs"
      open={open}
    >
      <DialogTitle sx={{color: 'primary.main'}}>{fileName}</DialogTitle>
      <DialogContent dividers>
      
        {IMAGE_EXTENSION_TYPE.includes(fileName?.split(".")[fileName?.split(".")?.length - 1]) ?   <img src={currentSelectedFile?.content} alt="Base64 Image" /> :   <iframe
            src={`${currentSelectedFile?.content}#toolbar=0`}
            width="100%"
            height="800px"
            frameborder="0"
            title="Document File"
          ></iframe>}
      
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleCancel} >
          Cancel
        </Button>
        <Button sx={{color: 'primary.main'}} onClick={()=>downloadFile(currentSelectedFile)}>Download</Button>
      </DialogActions>
    </Dialog>
        
        </>
    )
}
export default Fileviewdialog;