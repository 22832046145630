import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppLayout from "./pages/AppLayout/AppLayout.component";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UserApplications from "./pages/UserApplications/UserApplications.component";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoutes.component";
import Contact from "./pages/Contact/Contact.component";
import About from "./pages/About/About.component";
import Services from "./pages/Services/Service.component";
import UserBasicProfile from "./pages/UserDetails/UserBasicProfile/UserBasicProfile.component";
import UserDocuments from "./pages/UserDetails/UserDocuments/UserDocuments.component";
import UserDetails from "./pages/UserDetails/UserDetails.component";
import AppliedApplicationDetails from "./components/common/AppliedApplicationDetails/AppliedApplicationDetails.component";
import HomePage from "./pages/HomePage/HomePage.component";
import DocumentCheck from "./components/DocumentVerification/DocumentVerification.component";
import ApplicationCscCardContainer from "./pages/ApplicationCscCardContainer/ApplicationCscCardContainer.component";
import ApplicationCscCard from "./components/common/Cards/ApplicationCscCard/ApplicationCscCard.component";
import PlaceOrder from "./components/PlaceOrder/PlaceOrder.component";
import { useEffect } from "react";
import ApplicationDetails from "./components/common/ApplicationsDetails/ApplicationsDetails.component";
import DocumentUploadAndVerification from "./components/common/DocumentUploadAndVerification/DocumentUploadAndVerification.component";
import DocumentVerification from "./components/DocumentVerification/DocumentVerification.component";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: "services",
        element: <Services />,
        children: [
          {
            path: ":applicationsCategoryName/:applicationName",
            element: <ApplicationCscCardContainer />,
            children: [
              {
                path: '',
                element: <ApplicationDetails/>
              },
              {
                path: 'verify-documents',
                element: <ProtectedRoute><DocumentVerification /></ProtectedRoute>
              },
              {
              path: 'service-centers',
              element: <ProtectedRoute> <ApplicationCscCard /></ProtectedRoute>
            },
            {
              path: 'place-order',
              element:<ProtectedRoute><PlaceOrder /></ProtectedRoute>
            }
            ]
          }]
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/track-applications",
        element: (
          <ProtectedRoute>
            <UserApplications />
          </ProtectedRoute>
        )
      },
      {
        path: '/track-applications/order/:orderId/details',
        element: (
          <ProtectedRoute>
            <AppliedApplicationDetails />
          </ProtectedRoute>
        )
      },
      {
        path: "/:user",
        element: (
          <ProtectedRoute>
            <UserDetails />
          </ProtectedRoute>
        ),
        children: [{
          path: "profile",
          element: (
            <ProtectedRoute>
              <UserBasicProfile />
            </ProtectedRoute>
          ),
        },
        {
          path: "documents",
          element: (
            <ProtectedRoute>
            <DocumentUploadAndVerification/>
            </ProtectedRoute>
          ),
        }
        ]
      },
    ],
  },
]);

const App = () => {
  const { currentThemeMode } = useSelector((store) => store.theme);
  useEffect(() => {

  }, [currentThemeMode])
  const userTheme = localStorage.getItem('userTheme') || 'light';
  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#2B366E",
        contrastText: "#fff",
      },
      secondary: {
        main: "#ffffff",
        contrastText: "#000000",
      },
      error: {
        main: "#d32f2f",
      },
      warning: {
        main: "#ed6c02",
      },
      info: {
        main: "#0288d1",
      },
      success: {
        main: "#2e7d32",
      },
      background: {
        default: "#FFFFFF", // Default background color
        paper: "#F5F5F5", // Background color for paper elements
        footer: "#2B366E"
      },
      text: {
        primary: "#000000", // Primary text color
        secondary: "#FFFFFF", // Secondary text color
      },
    },

    // Define typography settings
    typography: {
      subtitle2: {
        color: "#2B366E",
      },
      body2: {
        color: "#000000",
      },
      button: {
        color: "#000000",
      },
    },
    overrides: {
      MuiInput: {
        input: {
          color: "#2B366E", // Change this color as needed
        },
      },
    },
  });
  const darkTheme = createTheme({
    palette: {
      mode: "dark", // You can switch to 'dark' for dark mode
      primary: {
        main: "rgb(11 175 238)", // Primary color for buttons, headings, etc.
      },
      secondary: {
        main: "#f3f3f3",
        contrastText: "#000000",
      },
      error: {
        main: "#d32f2f",
      },
      warning: {
        main: "#ed6c02",
      },
      info: {
        main: "#0288d1",
      },
      success: {
        main: "#2e7d32",
      },
      background: {
        default: "#333333", // Default background color
        paper: "#121212", // Background color for paper elements
        footer: "#333333"
      },
      text: {
        primary: "#f3f3f3", // Primary text color
        secondary: "#f3f3f3", // Secondary text color
      },
    },

    // Define typography settings
    typography: {
      subtitle2: {
        color: "#f3f3f3",
      },
      body2: {
        color: "#f3f3f3",
      },
      button: {
        color: "#f3f3f3",
      },
    },
    overrides: {
      MuiInputLabel: {
        root: {
          color: "#000000",
        },
      },
    },
  });
  return (
    <div className="App">
      <ThemeProvider
        theme={userTheme === "light" ? lightTheme : darkTheme}
      >
        {/* <Paper> */}
        <>
          <RouterProvider router={router} />
        </>
        {/* </Paper> */}
      </ThemeProvider>
    </div>
  );
};

export default App;

// palette: {
//   mode: "dark",
//   primary: {
//     main: "rgb(11 175 238)",
//     contrastText: "#253600",
//     backgroundColor:"#333333"
//   },
//   secondary: {
//     main: "#fff",
//   },
// },
// typography: {
//   subtitle2: {
//      color: "#E5E5E5",
//   },
//   button: {
//     color: "#E5E5E5",
//   }
// },
// overrides: {
//   MuiInputLabel: {
//     root: {
//       color: "#000000",
//     },
//   },
// },