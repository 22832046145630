import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { applicationDetailMapping, applicationDetails, applicationDetailsHeaderMapping } from "./detailMockData.js";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import './AppliedApplicationDetails.styles.scss'
import { formatApplicationDetailsData } from "./helpers.js";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from "@mui/material/Button";
import DeleteDialog from "../DeleteDialog/DeleteDialog.component.js";
import { APP_LABEL } from "../../../translations/index.js";
const AppliedApplicationDetails = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const currentApplication = applicationDetails.filter((detail) => detail.orderId === orderId)[0];
    const [viewDeleteDialog, setViewDeleteDialog] = useState(false);
    const [cancepApplicationDialog, setCancepApplicationDialog] = useState(false);
    const backToAppsList = () => {
        navigate(-1)
    }
    const removeDocumentsAccess = () => {
        console.log('Removing')
    }
    const cancelApplication = () => {
        console.log('cancelApplication')
    }
    return (
        <div className="application-details-container">
            <div className="application-details-header">
                <span className="header-back-icon">
                    <span className="icon-back" onClick={backToAppsList}><ArrowBackIosNewIcon /></span>
                    <span className="header-text"> Detailed Overview</span>
                </span>
                <span className="header-order"> {applicationDetailsHeaderMapping['orderId']} : {currentApplication['orderId']} </span>
            </div>
            {Object.entries(currentApplication).map(([key, values]) => {
                return key !== 'orderId' ?
                    <>
                        <List sx={{ backgroundColor: 'primary.main', marginBottom: '-1rem' }} className="details-section" key={applicationDetailsHeaderMapping[key]}>
                            <ListItem>
                                <ListItemText primary={applicationDetailsHeaderMapping[key]} sx={{ color: 'text.secondary' }} />
                            </ListItem>
                        </List>
                        <div className="application-details-data-container">
                            {
                                Object.entries(values).map((item) => {
                                    return <div className="application-details-data" key={applicationDetailMapping[item[0]]}>
                                        <div className={'application-detail-label'}>{(!applicationDetailMapping[item[0]].includes('Remove') && !applicationDetailMapping[item[0]].includes('Cancel')) && applicationDetailMapping[item[0]]}</div>
                                        {
                                            applicationDetailsHeaderMapping[key] === 'Actions' ? 
                                                <Button
                                                    onClick={(e) => {
                                                      
                                                         if(e.target.textContent === 'Remove Documents') {
                                                            setViewDeleteDialog(true)

                                                         } else {
                                                        setCancepApplicationDialog(true)
                                                         }
                                                    }}
                                                    variant="outlined"
                                                    sx={{ color: 'error.main', fontWeight: 600, fontSize: '0.85rem', textTransform: 'none',margin: '0.5rem 0  0 1.5rem' }}
                                                >
                                               {
                                                applicationDetailMapping[item[0]] === 'Remove Document' ? 'Remove Documents' : 'Cancel Application'
                                               }  
                                                </Button>
                                        :
                                                <div className={'application-detail-value'}>{formatApplicationDetailsData(item[0], item[1])}</div>
                                        }

                                    </div>
                                })
                            }
                        </div>
                    </>
                    : '';
            })
            }
   <DeleteDialog
        setViewDeleteDialog={setViewDeleteDialog}
        viewDeleteDialog={viewDeleteDialog}
        deleteAction={removeDocumentsAccess}
        leftButtonTitle={APP_LABEL['en'].leftBtnTitle}
        confirmationMessage={APP_LABEL['en'].removeDocument}
        rightButtonTitle={APP_LABEL['en'].rightBtnTitle}
      />
       <DeleteDialog
        setViewDeleteDialog={setCancepApplicationDialog}
        viewDeleteDialog={cancepApplicationDialog}
        deleteAction={cancelApplication}
        leftButtonTitle={APP_LABEL['en'].leftBtnTitle}
        confirmationMessage={APP_LABEL['en'].cancelApplication}
        rightButtonTitle={APP_LABEL['en'].rightBtnTitle}
      />
        </div>
    )
}
export default AppliedApplicationDetails;