import React, {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import './EducationDetails.styles.scss';

const EducationDetails = ({userProfile, setUserProfile}) => {
  const [expanded, setExpanded] = useState(false);
  const [marksType, setMarksType] = useState("CGPA");

  const handleChange1 = (event) => {
    setMarksType(event.target.value);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleEducationDetailsForm = (e) => {
    setUserProfile([
      {
        personal: {
          ...userProfile[0].personal,
        },
        contacts: { ...userProfile[0].contacts },
        education: { ...userProfile[0].education,
        
            [e.target.name]: e.target.value},
        identity: {
          ...userProfile[0].education,
         
        },
      },
    ]);
  };
  return (
    <>
    <div className="education-details-container">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="accordion-styles"
      >
        <AccordionSummary
        
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{
              width: "33%",
              flexShrink: 0,
              fontWeight: "700",
// fontSize: '0.875rem',
              color: "primary.main",
            }}
            className="accordion-label"
          >
            10th
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="form-container">
          <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                id="outlined-basic"
                label="Year of passing"
                name="secondaryYop"
             value={userProfile[0].education.secondaryYop}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
            <div className="education-form">
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marksType}
                // defaultValue="CGPA"
                //   label="CGPA"
                fullWidth={true}
                onChange={handleChange1}
              >
                <MenuItem value={'CGPA'}>CGPA</MenuItem>
                <MenuItem value={'Percentage'}>Percentage</MenuItem>
              </Select>
            </div>
            <div className="education-form">
              {marksType === "CGPA" ? (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="secondaryCgpa"
                  
                   value={userProfile[0].education.secondaryCgpa}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="CGPA"
                  placeholder="Enter your CGPA"
                  variant="outlined"
                />
              ) : (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="secondaryPercentage"
                  
                  value={userProfile[0].education.secondaryPercentage}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="Percentage"
                  placeholder="Enter your percentage"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className="accordion-styles"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography
            sx={{
              width: "33%",
              flexShrink: 0,
              fontWeight: "700",
           // fontSize: '0.875rem',
              color: "primary.main",
            }}
            className="accordion-label"
          >
            12th
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="form-container">
        <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Course name"
                placeholder="Art, Non-medical, Medical, Commerce etc..."
                name="seniorSecondaryCourseName"
                 value={userProfile[0].education.seniorSecondaryCourseName}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
          <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Year of passing"
                name="seniorSecondaryYop"
                value={userProfile[0].education.seniorSecondaryYop}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
            <div className="education-form">
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marksType}
                // defaultValue="CGPA"
                //   label="CGPA"
                fullWidth={true}
                onChange={handleChange1}
              >
                <MenuItem value={'CGPA'}>CGPA</MenuItem>
                <MenuItem value={'Percentage'}>Percentage</MenuItem>
              </Select>
            </div>
            <div className="education-form">
              {marksType === "CGPA" ? (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="seniorSecondaryCgpa"
                  
                  value={userProfile[0].education.seniorSecondaryCgpa}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="CGPA"
                  placeholder="Enter your CGPA"
                  variant="outlined"
                />
              ) : (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="seniorSecondaryPercentage"
                  
                   value={userProfile[0].education.seniorSecondaryPercentage}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="Percentage"
                  placeholder="Enter your percentage"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
        className="accordion-styles"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel6bh-header"
        >
          <Typography
            sx={{
              width: "33%",
              flexShrink: 0,
              fontWeight: "700",
         // fontSize: '0.875rem',
              color: "primary.main",
            }}
            className="accordion-label"
          >
           Diploma
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="form-container">
        <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Course name"
                placeholder="ITI, Polytechnic etc..."
                name="diplomaCourseName"
                 value={userProfile[0].education.diplomaCourseName}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
            <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Diploma branch"
                placeholder="Electronics, electrical, fitter, civil, mechanical etc..."
                name="diplomaBranch"
                value={userProfile[0].education.diplomaBranch}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
          <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Year of passing"
                name="diplomaYop"
                value={userProfile[0].education.diplomaYop}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
            <div className="education-form">
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marksType}
                // defaultValue="CGPA"
                //   label="CGPA"
                fullWidth={true}
                onChange={handleChange1}
              >
                <MenuItem value={'CGPA'}>CGPA</MenuItem>
                <MenuItem value={'Percentage'}>Percentage</MenuItem>
              </Select>
            </div>
            <div className="education-form">
              {marksType === "CGPA" ? (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="diplomaCgpa"
                  
                  value={userProfile[0].education.diplomaCgpa}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="CGPA"
                  placeholder="Enter your CGPA"
                  variant="outlined"
                />
              ) : (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="diplomaPercentage"
                  
                   value={userProfile[0].education.diplomaPercentage}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="Percentage"
                  placeholder="Enter your percentage"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className="accordion-styles"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography
            sx={{
              width: "33%",
              flexShrink: 0,
              fontWeight: "700",
 // fontSize: '0.875rem',
              color: "primary.main",
            }}
            className="accordion-label"
          >
            Graduation
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="form-container">
        <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Course name"
                name="graduationCourseName"
                placeholder="B.A, B.Sc, B.com, BCA, B.tech etc..."
                 value={userProfile[0].education.graduationCourseName}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
          <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Year of passing"
                name="graduationYop"
                value={userProfile[0].education.graduationYop}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
            <div className="education-form">
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marksType}
                // defaultValue="CGPA"
                //   label="CGPA"
                fullWidth={true}
                onChange={handleChange1}
              >
                <MenuItem value={'CGPA'} >CGPA</MenuItem>
                <MenuItem value={'Percentage'} >Percentage</MenuItem>
              </Select>
            </div>
            <div className="education-form">
              {marksType === "CGPA" ? (
                 <TextField
                 onChange={handleEducationDetailsForm}
                name="graduationCgpa"
                  
                 value={userProfile[0].education.graduationCgpa}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="CGPA"
                  placeholder="Enter your CGPA"
                  variant="outlined"
                />
              ) : (
                 <TextField
                 onChange={handleEducationDetailsForm}
                name="graduationPercentage"
                  
                   value={userProfile[0].education.graduationPercentage}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="Percentage"
                  placeholder="Enter your percentage"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        className="accordion-styles"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography
            sx={{
              width: "33%",
              flexShrink: 0,
              fontWeight: "700",
 // fontSize: '0.875rem',
              color: "primary.main",
            }}
            className="accordion-label"
          >
            Post graduation
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="form-container">
        <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Course name"
                name="postGraduationCourseName"
                placeholder="M.A, M.Sc, M.com, MCA, M.tech etc..."
                 value={userProfile[0].education.postGraduationCourseName}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
          <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Year of passing"
                name="postGraduationYop"
                 value={userProfile[0].education.postGraduationYop}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
            <div className="education-form">
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marksType}
                // defaultValue="CGPA"
                //   label="CGPA"
                fullWidth={true}
                onChange={handleChange1}
              >
                <MenuItem value={'CGPA'}>CGPA</MenuItem>
                <MenuItem value={'Percentage'}>Percentage</MenuItem>
              </Select>
            </div>
            <div className="education-form">
              {marksType === "CGPA" ? (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="postGraduationCgpa"
                  
                   value={userProfile[0].education.postGraduationCgpa}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="CGPA"
                  placeholder="Enter your CGPA"
                  variant="outlined"
                />
              ) : (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="postGraduationPercentage"
                  
                   value={userProfile[0].education.postGraduationPercentage}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="Percentage"
                  placeholder="Enter your percentage"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        className="accordion-styles"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel89bh-content"
          id="panel78bh-header"
        >
          <Typography
            sx={{
              width: "33%",
              flexShrink: 0,
              fontWeight: "700",
 // fontSize: '0.875rem',
              color: "primary.main",
            }}
            className="accordion-label"
          >
            Phd
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="form-container">
        <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Phd course"
                name="phdCourseName"
                placeholder="Phd in specialization"
                value={userProfile[0].education.phdCourseName}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
          <div className="education-form">
               <TextField
                 onChange={handleEducationDetailsForm}
                
                id="outlined-basic"
                label="Year of passing"
                name="phdYop"
                 value={userProfile[0].education.phdYop}
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                }}
                fullWidth={true}
                variant="outlined"
              />
            </div>
            <div className="education-form">
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marksType}
                // defaultValue="CGPA"
                //   label="CGPA"
                fullWidth={true}
                onChange={handleChange1}
              >
                <MenuItem value={'CGPA'}>CGPA</MenuItem>
                <MenuItem value={'Percentage'}>Percentage</MenuItem>
              </Select>
            </div>
            <div className="education-form">
              {marksType === "CGPA" ? (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="phdCgpa"
                  
                   value={userProfile[0].education.phdCgpa}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="CGPA"
                  placeholder="Enter your CGPA"
                  variant="outlined"
                />
              ) : (
                 <TextField
                 onChange={handleEducationDetailsForm}
                  name="phdPercentage"
                  
                   value={userProfile[0].education.phdPercentage}
                  id="outlined-basic"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                  }}
                  fullWidth={true}
                  label="Percentage"
                  placeholder="Enter your percentage"
                  variant="outlined"
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      </div>
      <div className="education-save-btn ">
     <div className="education-form">
         <Button
          fullWidth
          className="save-btn-mobile"
           variant="contained"
           color={"primary"}
           sx={{
             height: "2.5rem",
             fontSize: "1rem",
            // width: "50%"
           }}
           onClick={() => {
           }}
           //   onClick={handleUserAuthentication}
           //   endIcon={
           //     showLoader && (
           //       <>
           //         &nbsp; <CircularProgress color="inherit"  size={20} />
           //       </>
           //     )
           //   }
         >
           Save
         </Button>
       </div>
       </div>
   

       </>
  );
};
export default EducationDetails;
