const checkSignupFormValidation = ({
  username,
  email,
  password,
  // rePassword,
}) => {
  const validationDetails = {};
  const isUsernameValid = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/.test(username);
  const isEmailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  const isPasswordValid =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/.test(password);
  // const isPasswordValuesSame = password === rePassword;

  if (!isUsernameValid) {
    validationDetails.username =
      "Username should contain only letters and numbers";
  }
  if (!isEmailValid) {
    validationDetails.email = "Please enter valid email address";
  }
  if (!isPasswordValid) {
    validationDetails.password =
      "Password must at least 6 characters and contains numbers and alphabets(small and capital)";
  }
  // if (!isPasswordValuesSame) {
  //   validationDetails.rePassword = "Password and confirm password should be same";
  // }
  return validationDetails;
};
const hasErrorInSIgnupForm = (userDetailsError) => {
    if (userDetailsError.username?.length || userDetailsError.password?.length || userDetailsError?.email?.length){
      return true
    } else {
      return false;
    }
   
    }
export { checkSignupFormValidation,hasErrorInSIgnupForm };
