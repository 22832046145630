export const formatApplicationDetailsData = (property, value) => {
    if (property === 'amount') {
        return value + '/-';
    }
    if (property === 'documentList') {
        return (
            <ol className="document-list">
                {value.map((document, index) => (
                    <li key={document}>{`${index+1}. ${document}`}</li>
                ))}
            </ol>
        );
    }
    if(property === 'cscCenterMobile') {
        return `+91-${value}` 
    }
    return value;
}