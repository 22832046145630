import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonalDetails from "./PersonalDetails/PersonalDetails.component";
import EducationDetails from "./EducationDetails/EducationDetails.component";
import ContactDetails from "./ContactDetails/ContactDetails.component";
import IdentityDetails from "./IdentityDetails/IdentityDetails.component";

const steps = ["Personal", "Contact", "Education", "Identity"];

const UserBasicProfile = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
    const [userProfile, setUserProfile] = useState([
      {
        personal: {
          fullName: "Ajay kumar",
          gender: "male",
          dob: "12/01/1995",
          mobile: "7982019022",
        },
        contacts: {
          alternateEmail: "",
          alternateMobile: "",
          currentAddress: "",
          cityDistrict: "",
          pinCode: "",
          state: "",
          country: ""
        },
        education: {
          secondaryPercentage: "",
          secondaryCgpa: "",
          secondaryYop: "", 

          seniorSecondaryCourseName: "",
          seniorSecondaryPercentage: "",
          seniorSecondaryCgpa: "", 
          seniorSecondaryYop: "", 

          diplomaCourseName: "",
          diplomaBranch: "",
          diplomaPercentage: "",
          diplomaCgpa: "", 
          diplomaYop: "", 

          graduationCourseName: "",
          graduationPercentage: "",
          graduationCgpa: "", 
          graduationYop: "", 

          postGraduationCourseName: "",
          postGraduationPercentage: "",
          postGraduationCgpa: "", 
          postGraduationYop: "", 
          
          phdCourseName: "",
          phdPercentage: "",
          phdCgpa: "",
          phdYop: "",

        },
        identity: {
          aadharNumber: "",
          panNumber: ""
        }
      },
    ]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const getProfilePage = (step) => {
    switch (step) {
      case 0:
        return <PersonalDetails userProfile={userProfile} setUserProfile={setUserProfile} />;
      case 1:
        return <ContactDetails userProfile={userProfile} setUserProfile={setUserProfile} />;
      case 2:
        return <EducationDetails userProfile={userProfile} setUserProfile={setUserProfile}/>;
      default:
        return <IdentityDetails userProfile={userProfile} setUserProfile={setUserProfile}/>;
    }
  };
  return (
    <Box sx={{ padding: "1rem", width: "70%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color={"primary"} onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              {getProfilePage(activeStep)}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};
export default UserBasicProfile;
