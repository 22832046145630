import React from "react";
import { useNavigate } from 'react-router-dom';
import LoginDetails from "./LoginDetails/LoginDetails.component";
import OrderSummary from "./OrderSummary/OrderSummary.component";
import PaymentOptions from "./PaymentOptions/PaymentOptions.component";
import PriceDetails from "./PriceDetails/PriceDetails.component";
import './PlaceOrder.styles.scss';

const PlaceOrder = () => {
    const navigate = useNavigate();

    return (
        <div className="place-order-container">
            <div className="checkout-page">
                <section className="login-details-section">
                    <LoginDetails />
                </section>
                <section className="order-summary-section">
                    <OrderSummary />
                </section>
                <section className="price-details-section">
                    <PriceDetails />
                </section>
                <section className="payment-options-section">
                    <PaymentOptions />
                </section>
            </div>
        </div>
    );
};

export default PlaceOrder;
