import React from "react";
import Navbar from "../../../components/Navbar/Navbar.component";
import { FirebaseAuth } from "../../../auth/FirebaseAuth";

const AppHeadings = () => {
  return (
    <div>
      <FirebaseAuth>
        <Navbar />
      </FirebaseAuth>
     
    </div>
  );
};
export default AppHeadings;
