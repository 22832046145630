import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import flagIcon from "../../../../src/assets/svg/india-flag-icon.svg";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import './OADialogBody.styles.scss';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const  OADialogBody = (props) => {
  const { setIsMobileNumberValid, setIsEmailValid, setCurrentOpenTab } = props;
  const [value, setValue] = useState(0);
  const [mobileNumber, setMobileNumber] = useState('');
  const [emailId, setEmailId] = useState('');

  const handleChange = (event, newValue) => {
    event?.stopPropagation();
    setCurrentOpenTab(newValue);
    setValue(newValue);
  };

  const mobileNumberChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > 10) {
      return;
    }

    if (/^\d*$/.test(inputValue)) {
      setMobileNumber(inputValue);
      setIsMobileNumberValid(inputValue.length === 10);
    }
  };

  const emailChange = (e) => {
    const inputValue = e.target.value;
    setEmailId(inputValue);
    setIsEmailValid(validateEmail(inputValue));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Box className='oa-dialog-body'>
      <Box className='oa-dialog-body-container'>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Mobile Number" {...a11yProps(0)} sx={{ color: 'primary.main', textTransform: 'none' }} />
          <Tab label="Email ID" {...a11yProps(1)} sx={{ color: 'primary.main', textTransform: 'none' }} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <OutlinedInput
            id="outlined-adornment-mobile"
            value={mobileNumber}
            onChange={mobileNumberChange}
            startAdornment={
              <InputAdornment position="start">
                <img
                  src={flagIcon}
                  alt="flag-icon"
                  width={24}
                  height={24}
                />
              </InputAdornment>
            }
            sx={{ color: '#707070', fontWeight: 600 }}
            placeholder="Enter mobile number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </FormControl>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <OutlinedInput
            id="outlined-adornment-email"
            value={emailId}
            onChange={emailChange}
            startAdornment={
              <InputAdornment position="start">
                <MailOutlineIcon width='24' height='24' sx={{ color: 'primary.main' }} />
              </InputAdornment>
            }
            sx={{ color: '#707070', fontWeight: 600 }}
            placeholder="Enter email id"
          />
        </FormControl>
      </CustomTabPanel>
    </Box>
  );
}

export default OADialogBody;
