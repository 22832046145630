import React, { useEffect, useState } from 'react';
import { Button, LinearProgress, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { showToastr } from '../../ToastrMessage/Toast.helper';
import { APP_LABEL } from '../../../translations';

const DocumentUploadDialog = ({requiredDocument, setRequiredDocument,setDocumentsList,setOpenDialog }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false); // To manage uploading state
  const [error, setError] = useState(null); // To handle errors
  const [docType, setDocType] = useState(''); // To store selected document type
  const [uploadingFileDocName, setUploadingFileDocName] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    // Validate file type
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    if (!validTypes.includes(selectedFile.type)) {
      setError('Invalid file type. Please upload DOC,PDF or image type.');
      return;
    }

    if (!docType) {
      setError('Please select category.');
      return;
    }
    setFile(selectedFile);
    setError(null); // Clear any previous error

    // Simulate file upload
    const simulateUpload = () => {
      setUploading(true); // Set uploading state
      let uploadProgress = 0;
      const interval = setInterval(() => {
        uploadProgress += 10;
        setProgress(uploadProgress);
        if (uploadProgress >= 100) {
          clearInterval(interval);
          setUploading(false); // Reset uploading state
          setDocumentsList((prev) => ({
            ...prev,
            [event.target.files[0].name]: {
              file,
              progress,
            },
          }));
         const a = requiredDocument.map((item)=>{
            if(item?.id === uploadingFileDocName) {
              item.isUploaded = true;
            }
            return item;
          })
          setRequiredDocument(a);

          // Add file to the documents list
          setDocumentsList((prev) => ({
            ...prev,
            [selectedFile.name]: {
              file: selectedFile,
              type: docType,
              progress: 100,
              currentDateTime: new Date(),
              content: URL.createObjectURL(selectedFile), // For preview
            },
          }));
         
        }
      }, 100);
    };

    simulateUpload();
    
  };

  const selectFileUploadDocument = (event) => {
    setUploadingFileDocName(event.target.value);
    setDocType(event.target.value);
  };
  const handleReset = () => {
    setFile(null);
    setProgress(0);
    setError(null);
    setDocType('');
  };
  useEffect(()=>{
    if(progress === 100) {
      setOpenDialog(false);
      showToastr('success',`${APP_LABEL['en'].DOCUMENT_UPLOADED_SUCCESS_MESSAGE}`);
    }
  },[progress])

  return (
    <Box sx={{ padding: 2 }} >
      <FormControl fullWidth sx={{ marginBottom: 6 }} size='small'>
        <InputLabel id="doc-type-label" sx={{color: 'primary.main'}}>Select Category</InputLabel>
        <Select
          labelId="doc-type-label"
          value={docType}
          onChange={selectFileUploadDocument}
          label="Select Category"
          

        >
          {
            requiredDocument?.map((item)=>{
              if(!item?.isUploaded) {
                return  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              }
            })
          }
        </Select>
        {error && (
        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
          {error}
        </Typography>
      )}
     
      </FormControl>
      {file && (
        <Box sx={{ paddingTop: 3,paddingBottom:3 }}>
          <Typography variant="body2" color={'primary.main'}  className='file-name-progress-bar'>
           <span>
           {file.name}
            </span>
            <span>
            {`${Math.round(progress)}%`}
            </span>
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ borderRadius: '1rem', height: '0.5rem', marginTop: 1 }}
          />
           {
        progress === 100 && 
        <Typography variant="body2" color="green" sx={{ marginTop: 1 }}>
        {'Document file has been uploaded successfully.'}
      </Typography>
      }
        
        </Box>
      )}
       <div style={{display: 'flex', justifyContent: 'center'}}>
      <input
        type="file"
        accept="application/pdf, image/*"
        style={{ display: 'none' }}
        id="upload-doc-input"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-doc-input" >
        <Button
          variant="contained"
          component="span"
          endIcon={!uploading && <UploadFileIcon />}
          color="primary"
          size='large'
          sx={{textTransform: 'none'}}
          disabled={uploading}
        >
       {uploading ?   'Uploading...' : 'Upload '}   &nbsp;
        </Button>
  
      </label>
      </div>
     
     
    </Box>
  );
};

export default DocumentUploadDialog;
