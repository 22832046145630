import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { createContext } from "react";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  onAuthStateChanged 
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDGrcHKhuR9z_lnXaay-b1L8fhU5Uvk4Mc",
  authDomain: "oa-project-b6b15.firebaseapp.com",
  projectId: "oa-project-b6b15",
  storageBucket: "oa-project-b6b15.appspot.com",
  messagingSenderId: "700307363984",
  appId: "1:700307363984:web:921fe981c5c408ff18a015",
  measurementId: "G-H8PNBXW8PY",
  databaseURL: "https://oa-project-b6b15-default-rtdb.firebaseio.com",
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const googleLoginProvider = new GoogleAuthProvider();
const FirebaseAuthContext = createContext(null);

const FirebaseAuth = (props) => {
  const signUpUser = (email, password) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password);
  };
  const signInUser = (email, password) => {
    return signInWithEmailAndPassword(firebaseAuth, email, password);
  };
  const logoutUser = () => {};
  const resetPassword = (email) => {
    return sendPasswordResetEmail(firebaseAuth, email);
  };
  const signInWithGooglePopup = () => {
    return signInWithPopup(firebaseAuth, googleLoginProvider);
  }
  const updateUserProfile = (username) => {
   return updateProfile(firebaseAuth.currentUser, {
      displayName: username,
    })
  };
  const getCurrentLoggedInUser = () => {
   onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
     return user;
      } else {
        return null;
      }
  }
    )
}
const logOutUser = () => {
  return signOut(firebaseAuth)
}

  return (
      <FirebaseAuthContext.Provider
        value={{
          signUpUser,
          signInUser,
          logoutUser,
          resetPassword,
          updateUserProfile,
          signInWithGooglePopup,
          getCurrentLoggedInUser,
          firebaseAuth,
          logOutUser
        }}
      >
        {props.children}
      </FirebaseAuthContext.Provider>
  );
};

export {  FirebaseAuth, FirebaseAuthContext };
