import React from "react";
import {
    Avatar,
  Divider,
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import './UserProfileMobileDrawer.styles.scss';
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { userDisplayName } from "../../utils/user";
const UserProfileMobileDrawer = ({ toggleUserProfileMobileDrawer, userInfo,signOut, setToggleUserProfileMobileDrawer}) => {

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: `#44b700`,
      color: `#44b700`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const profileImageLetters = userDisplayName(userInfo.displayName)
  const name = userInfo?.displayName.split(" ").join("");
  return (
    <React.Fragment>
      <Drawer
        transitionDuration={700}
        PaperProps={{
          sx: {
            width: '100vw',
            top: '3.47rem'
          },
        }}
        elevation={0}
        variant="persistent"
        anchor="left"
        open={toggleUserProfileMobileDrawer}
        onClose={() => setToggleUserProfileMobileDrawer(false)}
      > 
        
        <div className="user-profile-account">Account</div>
        <MenuItem >
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginLeft: "0.80rem" 
            }}
          >
               <Stack
              direction="row"
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginRight: '0.5rem'
              }}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar alt="user" sx={{backgroundColor: "primary.main", padding: '0.05rem'}}>{profileImageLetters || "User"}</Avatar>
              </StyledBadge>
            </Stack>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <Typography
              variant="subtitle2"
              gutterBottom
              className="user-profile-name"
            >
              {userInfo.displayName}
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              className="user-profile-email"
            >
              {userInfo.email}
            </Typography>
            </div>
           
          </div>{" "}
        </MenuItem>
        <Divider />
        <MenuItem className="myprofile-item">
        <Link to={`/${name}/profile`} onClick={() => setToggleUserProfileMobileDrawer(false)}>
          <ListItemIcon style={{ marginLeft: "0.75rem" }}>
          <PersonOutlineIcon sx={{ color: "primary.main", fontSize: 24 }} />
          </ListItemIcon>
          Profile
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem className="myprofile-item">
        <Link to={`/${name}/documents`} onClick={() => setToggleUserProfileMobileDrawer(false)}>
          <ListItemIcon style={{ marginLeft: "0.75rem" }}>
          <FileCopyOutlinedIcon sx={{ fontSize: 24, color: "primary.main" }}/>
          </ListItemIcon>
          Documents
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={signOut} sx={{width: 'fit-content'}}>
          <ListItemIcon style={{ marginLeft: "0.75rem" }} >
            <PowerSettingsNewIcon sx={{ color: "#FF0000" }} />
          </ListItemIcon>

          <ListItemText
            className="list-items-text"
            sx={{ color: "#FF0000" }}
          >
            { "Logout"}
          </ListItemText>
        </MenuItem>
      </Drawer>
    
    </React.Fragment>
  );
};

export default UserProfileMobileDrawer;
